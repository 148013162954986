@use '../abstracts' as *;

/* lps_parts--flow
********************************************** */
/* --- 共通 --- */
.lps_parts--flow {
  overflow: hidden;
  @include mq(med, min, ps) {
    margin-left: 5rem;
  }
  .flow_item {
    position: relative;
    padding-right: rem(160);
    padding-top: rem(50);
    @include mq(med){
      padding-right: sprem(24);
      padding-top: sprem(24);
    }
    &:not(:last-child)::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: rem(8);
      left: 0;
      border-top: 1px solid var(--clr-sub-03);
    }
  }
  .ttlarea {
    position: relative;
    padding-top: rem(24);
    &::before {
      content: '';
      @include center-flex;
      inline-size: rem(16);
      background-color: var(--clr-sub-01);
      aspect-ratio: 1;
      border-radius: 50%;
      z-index: 1;
      position: absolute;
      top: rem(-50);
      left: 0;
      @include mq(med) {
        top: sprem(-24);
      }
    }
  }
  .num {
    display: inline-block;
    line-height: .8;
    &::before {
      content: counter(number, decimal-leading-zero);
      color: var(--clr-sub-01);
      font-family: var(--font-en);
      font-size: rem(56);
      font-weight: 400;
      letter-spacing: -.06em;
      @include mq(med) {
        font-size: sprem(36);
      }
    }
    & + .ttl {
      margin-top: rem(20);
      @include mq(med) {
        margin-top: sprem(16);
      }
    }
  }
  .year {
    color: var(--clr-sub-01);
    font-family: var(--font-en);
    font-size: rem(56);
    font-weight: 400;
    letter-spacing: -.06em;
    line-height: .8;
    & + .ttl {
      margin-top: rem(20);
    }
  }
  .ttl {
    position: static;
  }
  .clr-wht .ttl {
    color: var(--clr-wht);
  }
  .text {
    margin-top: rem(24);
    @include mq(med) {
      margin-top: sprem(20);
    }
  }
  .img {
    @include mq(med) {
      &:not(:last-child) {
        margin-bottom: sprem(24);
      }
    }
  }
  .mce-content-body {
    margin-bottom: 0 !important;
    @include mq(med, min, ps) {
      flex: 1;
    }
  }
}

/* --- 通常 --- */
.lps_parts--flow:not(.flow-num) {
  
  .flow_item:not(:last-child) .ttl::after {
    margin-top: calc((rem($flow-dot) + ((1lh - rem($flow-dot)) / 2)) + rem($flow-bd-gap));
    margin-bottom: calc((1lh - rem($flow-dot)) / -2 + rem($flow-bd-gap));
    @include mq(sp) {
      margin-top: calc((sprem($flow-dot-sp) + ((1lh - sprem($flow-dot-sp)) / 2)) + sprem($flow-bd-gap-sp));
      margin-bottom: calc((1lh - sprem($flow-dot-sp)) / -2 + sprem($flow-bd-gap-sp));
    }
  }
}

/* --- 連番 --- */
.lps_parts--flow.flow-num {
  .flow_item {
    counter-increment: number;
  }
  .flow_item:not(:last-child) .ttl::after {
    margin-top: calc(rem($flow-num-dot) + ((1lh - rem($flow-num-dot)) / 2) + rem($flow-bd-gap));
    margin-bottom: calc((1lh - rem($flow-num-dot)) / -2 + rem($flow-bd-gap));
    @include mq(sp) {
      margin-top: calc((sprem($flow-num-dot-sp) + ((1lh - sprem($flow-num-dot-sp)) / 2)) + sprem($flow-bd-gap-sp));
      margin-bottom: calc((1lh - sprem($flow-num-dot-sp)) / -2 + sprem($flow-bd-gap-sp));
    }
  }
}