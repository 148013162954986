@use "../abstracts" as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  transition: var(--transit-default);
}
.icon-new_tab {
  @include rect(14);
}
.icon-pdf {
  @include rect(27, 12);
}
.lps_sec {
  position: relative;
  z-index: 2;
  &:nth-child(odd) {
    .bg-wht {
      background-color: var(--clr-bg-01);
      box-shadow: 0 0 rem(14) 0 rgba(0, 0, 0, 0.05);
    }
  }
}
.lps_sec .bg-wht .clr-wht,
.lps_sec .bg-wht .clr-wht * {
  color: var(--clr-text-primary);
}
.lps_sec[style*="background-color"],
.lps_sec:has(.lps_sec-bg[style*="background-image"]) {
  @include mq(sp, min, ps) {
    padding-left: rem(40);
    padding-right: rem(40);
  }
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

.lps_side_main {
  @include mq(sp, min, ps) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    main {
      width: calc(100% - rem(440));
      @include mq(2240) {
        .lps_parts--child.inner,
        .ttl-01.inner,
        .ttl-02.inner {
          margin-left: 0;
          &.inner-sm {
            margin-left: auto;
          }
        }
        .lps_parts--child.inner.inner-lg.bg-wht {
          margin-left: auto;
        }
        .ttl-01,
        .ttl-02 {
          padding-left: rem(40);
        }
      }
    }
  }
}

.lps_side_main .side_column {
  position: relative;
  z-index: 2;
  @include mq(sp, min, ps) {
    width: rem(440);
    position: sticky;
    top: rem($header-hgt-fx);
    bottom: 0;
    padding-top: rem(40);
    padding-bottom: rem(40);
  }
  @include mq(sp) {
    padding-bottom: sprem(24);
  }
}
.side_column > .inner {
  @include mq(sp, min, ps) {
    max-width: rem(270);
    overflow-y: auto;
    height: calc(100vh - rem($header-hgt-fx));
    padding-bottom: rem(24);
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.side_cta {
  margin-top: rem(40);
  @include mq(sp) {
    margin-top: sprem(24);
  }
}
.side_cta--cont {
  background-color: var(--clr-primary-dark);
  color: var(--clr-wht);
  padding: rem(20) rem(18);
  @include mq(sp) {
    padding: sprem(12) sprem(10);
  }
  .ttl {
    text-align: center;
    background-color: var(--clr-sub-01);
    line-height: 1.6;
    letter-spacing: -0.05em;
    padding: rem(4);
    margin-bottom: rem(10);
    @include mq(1440) {
      font-size: rem(18);
    }
  }
}
.side_cta--tel {
  text-align: center;
}
.side_cta--tel a {
  display: inline-flex;
  &::before {
    content: "";
    width: rem(20);
    height: rem(26);
    background-image: url("../images/common/icon-phone.svg");
    background-size: cover;
  }
  .num {
    color: var(--clr-wht);
    font-size: rem(34);
    letter-spacing: -0.04em;
  }
}
.side_cta--comment {
  margin-top: rem(16);
  @include mq(sp) {
    margin-top: sprem(8);
  }
}
.side_cta--comment .micro_copy {
  font-size: rem(12);
  inset-inline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(8);
  color: var(--clr-wht);
  text-align: center;
  margin-bottom: rem(4);
  @include mq(sp) {
    font-size: sprem(15);
    margin-bottom: 0;
  }
  &::before,
  &::after {
    content: "";
    width: 2px;
    height: rem(15);
    background-color: var(--clr-wht);
  }
  &::before {
    transform: rotate(-40deg);
  }
  &::after {
    transform: rotate(40deg);
  }
}
.side_cta--comment > .flex {
  @include mq(sp) {
    justify-content: center;
  }
}
.side_cta--comment .icon {
  width: rem(64);
  height: rem(64);
  background-color: #fff;
  border-radius: 50%;
  padding: 4px 4px 0;
  overflow: hidden;
}
.side_cta--comment .txtarea {
  font-size: rem(12);
  @include mq(sp, min, ps) {
    flex: 1;
  }
  @include mq(sp) {
    font-size: sprem(15);
  }
}
.side_cta--comment .txt {
  line-height: 1.6;
  letter-spacing: -0.05em;
}
.side_cta--btn a {
  position: relative;
  z-index: 1;
  display: block;
  letter-spacing: -0.03em;
  padding: rem(12) rem(64) rem(12) rem(38);
  width: 100%;
  color: var(--clr-primary-dark);
  background-color: var(--clr-wht);
  text-decoration: none;
  @include mq(1440) {
    font-size: rem(18);
  }
  .arrow {
    width: rem(24);
    position: absolute;
    right: rem(24);
    top: calc(50% - rem(24) / 2);
  }
  .arrow .icon-arrow {
    width: rem(10);
    height: rem(6);
  }
  &:hover {
    background-color: var(--clr-sub-01);
    color: var(--clr-wht);
    .arrow {
      color: var(--clr-text-primary);
      background-color: var(--clr-wht);
    }
    .arrow .icon-arrow {
      fill: var(--clr-sub-01);
    }
  }
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 2;
  &:not(:last-child) {
    margin-bottom: rem(120);
    @include mq(sp) {
      margin-bottom: sprem(60);
    }
  }
}
.lps_parts:not(.lps_parts--slide.slide-fit) {
  @include mq(sp, min, ps) {
    padding-left: rem(40);
  }
}
.lps_parts > .lps_parts:not(.lps_parts--slide.slide-fit) {
  padding-left: 0;
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: rem(24);
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
}

/* relation_post
********************************************** */
.editor-styles-wrapper,
.mce-content-body {
  .relation_post a {
    text-decoration: none;
  }
}
.relation_post {
  #ez-toc-container {
    display: none;
  }
}
