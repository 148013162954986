@use "../abstracts" as *;

/* ##############################################################################

    SINGLE

############################################################################## */

.single {
  .page_ttl {
    padding-top: rem(32);
    padding-bottom: rem(24);
    .ttlarea {
      display: none;
    }
  }
  .breadcrumbs {
    @include mq(sp, min, ps) {
      text-align: right;
    }
    @include mq(sp) {
      .inner {
        padding: 0;
      }
    }
  }
  .post--date {
    color: var(--clr-text-secondary);
    font-size: rem(14);
    @include mq(sp) {
      font-size: sprem(11);
    }
  }
  .ttl-03 {
    line-height: 1.5;
    letter-spacing: perc(-3.2, 40, em);
  }
  // .cat_list a {
  //   padding: 0.125em 1em;
  //   background: #c8e6f5;
  //   color: var(--clr-primary-dark);
  //   @include mq(sp) {
  //     padding: 0 0.75em;
  //     font-size: sprem(11);
  //   }
  // }
}

/* --- アイキャッチ --- */
.eyecatch {
  margin-bottom: rem(40);
}

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}
div#ez-toc-container {
  max-width: rem(820);
  margin-left: auto;
  margin-right: auto;
  padding: rem(40) rem(64);
  background-color: #fdfdfd;
  border: 1px solid #d2d2d2;
  border-radius: 0;
  @include mq(sp) {
    padding: sprem(20);
  }
  p.ez-toc-title {
    display: block;
    color: var(--clr-primary-default);
    font-weight: bold;
    font-size: rem(24);
    text-align: center;
  }
  a,
  a:hover,
  a:visited {
    color: var(--clr-primary-default) !important;
  }
}
.ez-toc-title-container {
  display: block;
  margin-bottom: rem(10);
}
#ez-toc-container.counter-hierarchy {
  ul {
    font-size: calc-fz(17);
  }
  ul li {
    margin-top: rem(8);
  }
  ul ul {
    margin-left: rem(16);
  }
  ul ul a {
    position: relative;
    display: inline-block;
    padding-left: rem(16);
    &::before {
      content: "";
      position: absolute;
      top: rem(10);
      left: 0;
      width: rem(8);
      height: 0;
      border-bottom: 1px solid;
    }
  }
}

.posts-releated {
  overflow: hidden;
}

/* blog / news
**************************************** */
.article-blog--info,
.article-news--info {
  padding: rem(70) rem(80);
  background-color: var(--clr-wht);
  @include mq(sp) {
    padding: sprem(24);
    margin-left: -5vw;
    margin-right: -5vw;
  }
}
.news--content td,
.news--content th {
  padding: rem(20) rem(10);
}
.news--content img {
  width: rem(120);
}
/* product
**************************************** */
.article-product--info {
  .mce-box {
    width: 85%;
    padding: rem(48) rem(80);
    margin-left: auto;
    margin-right: auto;
    @include mq(sp) {
      width: 100%;
      padding: sprem(28) sprem(32);
    }
  }
  .list li {
    padding-bottom: 0;
    @include mq(sp, min, ps) {
      padding-right: rem(32);
    }
  }
  .product {
    padding-block: rem(56);
    border-bottom: solid 1px var(--clr-sub-03);
    @include mq(sp) {
      padding-block: sprem(24);
    }
    &:first-child {
      border-top: solid 1px var(--clr-sub-03);
    }
    .img {
      width: rem(320);
      @include mq(sp) {
        width: 100%;
      }
    }
    .txtarea {
      flex: 1;
    }
    .ttl-03 {
      font-size: rem(32);
      color: var(--clr-sub-01);
      @include mq(sp) {
        font-size: sprem(22);
      }
    }
    .link {
      margin-top: rem(40);
      @include mq(sp) {
        margin-top: sprem(22);
      }
    }
  }
  .lps_sec .inner {
    width: 100%;
    max-width: 100%;
  }
}

/* case
**************************************** */
.case_list {
  display: grid;
  grid-template-columns: rem(272) 1fr;
  gap: rem(32);
  @include mq(sp) {
    grid-template-columns: 1fr;
    gap: 0;
    &:last-child {
      border-bottom: solid 1px var(--clr-sub-03);
    }
  }
  &::before,
  &::after {
    display: none;
  }
  > * {
    padding: rem(32) rem(20) rem(22);
    @include mq(sp, min, ps) {
      border-bottom: solid 1px var(--clr-sub-03);
    }
    @include mq(sp) {
      padding: sprem(16) sprem(4);
      &:last-child {
        border-top: none;
        padding-top: 0;
      }
    }
  }
}
.case_list--ttl {
  border-bottom: solid 2px var(--clr-sub-01);
  @include mq(sp) {
    margin-bottom: 1em;
    color: var(--clr-sub-01);
    border-bottom: solid 2px var(--clr-sub-01);
  }
}
.case_list--cont {
  margin-top: 0;
  @include mq(sp, min, ps) {
    padding-left: rem(56);
  }
}
.case_contents {
  padding: rem(70) rem(80);
  background-color: var(--clr-wht);
  @include mq(sp) {
    padding: sprem(24);
    margin-left: -5vw;
    margin-right: -5vw;
  }
}
.products_list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rem(18);
  @include mq(sp) {
    grid-template-columns: repeat(1, 1fr);
    gap: sprem(12);
  }
  .post {
    display: grid;
    grid-template-columns: rem(200) 1fr;
    gap: rem(24);
    padding: rem(16);
    background-color: var(--clr-wht);
    @include mq(sp) {
      grid-template-columns: sprem(120) 1fr;
      gap: sprem(16);
    }
    @media (hover: hover) {
      &:hover {
        .arrow {
          color: var(--clr-text-primary);
          background-color: var(--clr-mono-01);
        }
        .arrow svg {
          fill: var(--clr-sub-01);
        }
      }
    }
    @include mq(sp, min, ps) {
      &:only-child {
        grid-area: 1 / 1 / 3 / 3;
      }
    }
  }
  .post--txtarea {
    @include mq(sp, min, ps) {
      padding-top: rem(10);
    }
  }
  .post--btn {
    display: flex;
    align-items: center;
    gap: rem(24);
    @include mq(sp) {
      display: none;
    }
  }
}
.case_business .btn a {
  width: rem(380);
  max-width: 100%;
  border: solid 1px var(--clr-sub-03);
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}
#pass input[name="post_password"] {
  padding: rem(5);
  border: solid 1px #aaa;
  outline: none;
}
#pass input[type="submit"] {
  padding: rem(4) rem(6);
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: rem(4);
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default);
}
#pass input[type="submit"]:hover {
  opacity: 0.7;
}
/* teikinin
**************************************** */
.single-teikibin .cta_download {
  margin-bottom: rem(24) !important;
}
/* share
**************************************** */
.share--list a {
  @include center-flex;
  height: rem(56);
  padding: 0.5em;
  line-height: 1;
  font-size: calc-fz(16);
  font-weight: bold;
  border: solid 1px var(--clr-sub-03);
  background-color: var(--clr-wht);
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.share--list li {
  &.x a {
    color: #000;
  }
  &.facebook a {
    color: #2477f2;
  }
  &.line a {
    color: #27c754;
  }
  &.pocket a {
    color: #ef4056;
  }
  &.linkedin a {
    color: #2867b2;
  }
  &.hatena a {
    color: #29a4de;
  }
}
.share--list img {
  margin-right: rem(10);
}
.share--list a:hover {
  opacity: 0.5;
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: calc-clamp(16, $wid-var);
  white-space: nowrap;
  font-family: var(--font-en), var(--font-primary);
  color: var(--clr-primary-default);
  @include mq(sp) {
    gap: sprem(8);
  }
}
.wp-pagenavi > * {
  @include center-flex;
}
.wp-pagenavi .pages {
  width: 100%;
  margin-bottom: 1em;
}
.wp-pagenavi .current {
  color: var(--clr-wht);
  background-color: var(--clr-primary-default);
  border-color: var(--clr-primary-default);
  font-size: rem(20);
  @include mq(sp) {
    font-size: sprem(15);
  }
}
.wp-pagenavi a {
  position: relative;
  padding: 0 1em;
  height: calc-clamp(80, $wid-var);
  color: var(--clr-primary-default);
  background-color: #fff;
  font-size: rem(20);
  @include mq(sp) {
    height: sprem(50);
    font-size: sprem(15);
  }
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
  }
}
.archive .wp-pagenavi > *:not(.pages) {
  width: calc-clamp(80, $wid-var);
  aspect-ratio: 1;
  @include mq(sp) {
    width: sprem(50);
  }
}
.wp-pagenavi-single a[rel="prev"],
.wp-pagenavi-single a[rel="next"] {
  aspect-ratio: 1;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  border-top: 2px solid;
  border-right: 2px solid;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 0.5em;
  aspect-ratio: 1;
}
.wp-pagenavi a[rel="prev"]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}
.wp-pagenavi a[rel="next"]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}
.wp-pagenavi-single a[rel="prev"]::before {
  transform: rotate(45deg);
}
.wp-pagenavi-single a[rel="next"]::before {
  transform: rotate(-135deg);
}
.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: rem(-6);
}
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: rem(-6);
}

.wp-pagenavi-single {
  a {
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
    &:hover {
      color: var(--clr-primary-default);
      background-color: var(--clr-wht);
    }
  }
}
