@use '../abstracts' as *;
@use 'sass:math';

/* txt
**************************************** */
.txt-lg {
  font-size: calc-fz(18);
  @include mq(sp) {
    font-size: sprem(15);
  }
}
.txt-sm {
  font-size: calc-fz(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}

//カラーを出力
@each $key, $color in $colors {
  .#{$key},
  .#{$key} * { color: var(--#{$key}); }
}
//ブロックエディタ用
@each $key, $color in $colors {
  .has-#{$key}-color,
  .has-#{$key}-color * { color: var(--#{$key}); }
}

/* --- font-size --- */
// フォントサイズを8pxから140pxまでREMで生成
@for $i from 8 through 140 {
  .fz#{$i} {
    font-size: calc-fz($i);
    @include mq(sp) {
      font-size: rem($i);
    }
  }
  .fz-sp#{$i} {
    @include mq(sp) {
      font-size: sprem($i) !important;
    }
  }
}

/* --- font-family --- */
.font-jp {
  font-family: var(--font-jp);
}
.font-en {
  font-family: var(--font-en);
  font-weight: 400;
  line-height: .8
}
.font-en2 {
  font-family: var(--font-en2);
  font-weight: 600;
  line-height: .8
}

/* --- clip-txt --- */
// 省略行を1から4まで生成
@for $i from 1 through 4 {
  .clip-txt#{$i} {
    @include clip-txt($i);
  }
}

/* txt-ctr
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}
.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@include mq(sp) {
  .txt-ctr-pc,
  .txt-rgt-pc {
    text-align: left;
  }
  .txt-ctr-sp {
    text-align: center;
  }
}

/* txtarea
**************************************** */
.txtarea p:not(:last-child) {
  margin-bottom: 1em;
}