@use "../abstracts" as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

.main_side {
  display: grid;
  grid-template-columns: rem(320) minmax(0, 1fr);
  gap: rem(80);
  @include mq(med) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: sprem(40);
  }
  @include mq(med, min, ps) {
    .main_column {
      order: 2;
    }
    .side_column {
      order: 1;
    }
  }
}

/* main_column
**************************************** */

/* --- post --- */
.post {
  position: relative;
  @media (hover: hover) {
    &:hover {
      .post--img img {
        scale: 1.05;
        opacity: 0.7;
      }
    }
  }
}
.post--img {
  background: linear-gradient(180deg, rgba(2, 22, 122, 0.08) 30%, rgba(203, 215, 229, 0) 100%);
  overflow: clip;
  img {
    transition: var(--transit-default);
  }
  .img-contain {
    max-width: 80%;
  }
}
.post--link {
  color: var(--clr-primary-dark);
  @media (hover: hover) {
    &:not([href="javascript:void(0);"]):hover {
      color: var(--clr-primary-default);
    }
    &:hover svg {
      fill: var(--clr-primary-default);
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.post--date {
  color: var(--clr-primary-dark);
  font-family: var(--font-en);
  font-size: rem(18);
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: rem(2);
}
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: calc-fz(15);
  color: var(--clr-sub-01);
  @include mq(sp) {
    font-size: sprem(14);
  }
  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
    }
  }
  &::before {
    content: "#";
  }
}

/* side_column
**************************************** */
.side_section:not(:last-child) {
  margin-bottom: rem(64);
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
}
.side--ttl {
  letter-spacing: perc(-0.72, 24, em);
  line-height: 1.6;
  margin-bottom: rem(24);
  color: var(--clr-primary-dark);
  @include mq(sp) {
    font-size: sprem(24);
    margin-bottom: sprem(16);
  }
}
.side--ttl span {
  letter-spacing: 0.15em;
  display: block;
  margin-top: rem(4);
  line-height: 1;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: perc(-0.42, 14, em);
}

/* --- list --- */
.side--list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: rem(8);
}
.side--list a {
  position: relative;
  display: block;
  padding-left: rem(38);
  color: var(--clr-primary-dark);
  @include mq(sp) {
    padding-left: sprem(16);
  }
  &::before {
    content: "";
    position: absolute;
    top: 1em;
    left: 0;
    display: block;
    width: rem(20);
    border-top: solid rem(2);
    transform: translateY(-150%);
    opacity: 0.8;
    @include mq(sp) {
      width: sprem(12);
    }
  }
  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
    }
  }
}
.side--list .children {
  padding-left: rem(16);
}
.side--list2 {
  display: flex;
  flex-wrap: wrap;
  gap: rem(8);
  li {
    width: rem(360);
  }
}
.side--list2 a {
  position: relative;
  display: block;
  padding: rem(24) rem(100) rem(24) rem(60);
  color: var(--clr-primary-dark);
  background: var(--clr-wht);
  box-shadow: 0 0 rem(20) 0 rgba(190, 206, 230, 0.25);
  font-size: rem(20);
  @include mq(sp) {
    padding: sprem(12) sprem(32) sprem(12) sprem(16);
    font-size: sprem(14);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: rem(40);
    display: block;
    @include rect(8);
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    @include mq(sp) {
      right: sprem(14);
      @include rect(7);
    }
  }
  @media (hover: hover) {
    &:hover {
      opacity: 1;
      background: #c8e6f5;
    }
  }
}

/* --- side--list-tag --- */
.side--list-tag {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  gap: rem(4);
  &:not([class*="mgn-btm"]) {
    margin: 0 !important;
  }
  a {
    display: block;
    padding: 0.5em 0.7em;
    color: var(--clr-primary-dark) !important;
    font-size: 1rem;
    letter-spacing: -0.02em;
    line-height: 1.2;
    background-color: var(--clr-bg-04);
    &::before {
      content: "#";
    }
  }
  .current a {
    color: var(--clr-wht) !important;
    background-color: var(--clr-primary-dark);
  }
}
@include mq(sp) {
  .post .side--list-tag a {
    font-size: sprem(10);
  }
}

/* --- post --- */
.posts-side {
  .post {
    display: grid;
    grid-template-columns: rem(120) minmax(0, 1fr);
    gap: rem(16);
    align-items: center;
    &:not(:last-child) {
      margin-bottom: rem(16);
    }
  }
  .post--date {
    margin-bottom: 0;
  }
  .post--ttl {
    line-height: var(--line-height-hdr);
  }
  .cat_list a {
    font-size: calc-fz(12);
  }
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  color: var(--clr-primary-dark);
  &::after {
    content: "";
    display: block;
    @include rect(8);
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(135deg);
    transition: var(--transit-default);
  }
  &.active::after {
    transform: rotate(315deg);
  }
}
.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
  .archive_list {
    position: relative;
    &:not(:last-child) {
      margin-right: rem(24);
    }
  }
  .archive_list a {
    display: block;
    padding: 0.25em 1em;
    text-align: left;
    &:not(:last-child) {
      border-bottom: 1px solid var(--clr-sub-03);
    }
    @media (hover: hover) {
      &:hover {
        background-color: var(--clr-mono-01);
      }
    }
  }
  .archive_list--label {
    margin-right: rem(8);
  }
  .archive_list--btn {
    cursor: pointer;
    border: none;
    outline: none;
    appearance: none;
    padding: 0.25em 4em 0.25em 1em;
    background-color: var(--clr-wht);
    border: 1px solid var(--clr-sub-03);
    color: inherit;
    font-family: var(--font-primary);
    font-size: calc-fz(16);
    line-height: var(--line-height-default);
    font-weight: 500;
    transition: var(--transit-default);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0.4em;
      right: 0.8em;
      margin: auto;
      width: 0.5em;
      height: 0.5em;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      transition: var(--transit-default);
    }
    @media (hover: hover) {
      &:hover {
        background-color: var(--clr-mono-01);
      }
    }
  }
  .active .archive_list--btn::after {
    bottom: -0.2em;
    transform: rotate(225deg);
  }
  .archive_list--menu {
    position: absolute;
    z-index: 1;
    width: 100%;
    background-color: var(--clr-wht);
    border: 1px solid var(--clr-sub-03);
    visibility: hidden;
    margin-top: -1px;
    opacity: 0;
    transition: var(--transit-default);
  }
  .active .archive_list--menu {
    visibility: visible;
    opacity: 1;
  }
}

/* --- bnr --- */
.side--bnr a {
  display: block;
  @media (hover: hover) {
    &:hover {
      opacity: 0.9;
    }
  }
}

/* teikibin
**************************************** */
.posts-teikibin .post--img {
  border: 1px solid #eee;
}

/* blog
**************************************** */
.posts-blog {
  display: grid;
  gap: rem(32);
  @include mq(sp) {
    gap: sprem(12);
  }
  .post {
    display: grid;
    gap: rem(32);
    grid-template-columns: 0.9fr 1.1fr;
    align-items: center;
    @include mq(sp) {
      gap: sprem(12);
    }
  }
  .post--ttl {
    font-size: calc-fz(20);
    @include mq(sp) {
      font-size: sprem(16);
      line-height: 1.4;
    }
  }
  .post--date {
    font-size: calc-fz(15);
    color: var(--clr-text-placeholder);
    @include mq(sp) {
      font-size: sprem(14);
    }
  }
}
.posts-blog.row,
.relation_post .posts-blog {
  gap: rem(80) rem(40);
  @include mq(sp) {
    gap: sprem(24) sprem(12);
  }
  .post {
    display: grid;
    grid-row: span 3;
    grid-template-rows: subgrid;
    grid-template-columns: 1fr;
    align-items: flex-start;
  }
  // .post--txtarea {
  //   display: grid;
  //   grid-row: span 3;
  //   grid-template-rows: subgrid;
  //   gap: 0;
  // }
  .post--txt {
    opacity: 0.5;
  }
}
.posts-blog.row.swiper-wrapper {
  display: flex;
  gap: 0;
}
.posts-blog.row:where(:not(.swiper-wrapper)),
.relation_post .posts-blog {
  grid-template-columns: repeat(2, 1fr);
}

/* news
**************************************** */

/* --- posts-news --- */
.posts-news {
  .post {
    position: relative;
    padding: rem(20) rem(72) rem(20) rem(16);
    border-bottom: 1px solid var(--clr-mono-01);
    @include mq(sp) {
      padding: rem(20) rem(56) rem(20) 0;
    }
  }
  .post--info {
    gap: rem(24);
    @include mq(sp) {
      gap: sprem(16);
    }
  }
  .post--info.flex .post--date + .cat_list {
    margin-left: 0;
  }
  .cat_list {
    font-size: calc-fz(18);
    @include mq(sp) {
      font-size: sprem(14);
    }
  }
  .post--ttl {
    font-size: calc-fz(18);
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
  .arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    right: rem(16);
    margin: auto;
    @include rect(56);
    cursor: pointer;
    @include mq(sp) {
      right: 0;
      @include sprect(40);
    }
    svg {
      fill: var(--clr-wht);
    }
  }
  .arrow:not(:has(svg)) {
    &::after {
      content: "";
      display: inline-block;
      width: rem(7);
      height: rem(7);
      border-top: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
    }
  }
  @media (hover: hover) {
    .post--link:hover .arrow {
      color: var(--clr-sub-01);
      background-color: var(--clr-wht);
      border: solid 1px;
      svg {
        fill: var(--clr-sub-01);
      }
    }
  }
}

/* --- news--archive --- */
.subpage .posts-news {
  .post {
    border-bottom-color: var(--clr-mono-02);
  }
}

/* case
**************************************** */
.posts-case:where(:not(.swiper-wrapper)) {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: rem(34);
  @include mq(sp) {
    grid-template-columns: 1fr;
    gap: sprem(40);
  }
}
.posts-case {
  // @include mq(sp) {
  //   padding: sprem(16);
  //   background-color: var(--clr-wht);
  // }
  .post {
    transition: var(--transit-default);
  }
  .post--txtarea {
    padding-top: rem(16);
    @include mq(sp) {
      padding-top: sprem(8);
    }
  }
  .post--info {
    margin-bottom: 0.5em;
    line-height: 2.1;
    column-gap: rem(16);
    &::before {
      content: "";
      height: 1em;
      border-right: 1px solid var(--clr-text-placeholder);
      margin-block: auto;
    }
  }
  .cat_list {
    order: -1;
  }
  .cat_list a {
    &::before {
      display: none;
    }
    &:not(:last-child)::after {
      content: "、";
    }
  }
  .post--company {
    position: relative;
    font-size: rem(15);
    color: var(--clr-mono-05);
    @include mq(sp) {
      font-size: sprem(11);
      line-height: 1.2;
    }
  }
  .post--ttl {
    font-size: rem(18);
    line-height: 1.9;
    color: var(--clr-primary-dark);
    @include mq(sp) {
      font-size: sprem(15);
      line-height: 1.5;
    }
  }
}
.clr-wht .posts-case *,
.clr-wht ~ .ttl-02 *,
.clr-wht ~ .lps_parts--column .posts-case * {
  color: var(--clr-wht);
  @media (hover: hover) {
    &.post:hover {
      opacity: 0.8;
    }
  }
}
.relation_post .posts-case .post--ttl {
  font-size: rem(18) !important;
}

/* product
**************************************** */
.posts-product:where(:not(.swiper-wrapper)) {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: rem(34);
  @include mq(sp) {
    grid-template-columns: 1fr;
    gap: sprem(20) sprem(12);
  }
}
.posts-product {
  @media (hover: hover) {
    .post:hover .arrow {
      color: var(--clr-text-primary);
      background-color: var(--clr-wht);
      svg {
        fill: var(--clr-sub-01);
      }
    }
  }
  .post--ttl {
    @include mq(sp, min, ps) {
      display: grid;
      grid-template-columns: 1fr rem(44);
      gap: 1rem;
      align-items: center;
    }
  }
  .arrow {
    @include mq(sp) {
      display: none;
    }
  }
}
