@use "../abstracts" as *;
/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes scroll-img {
  0% {
      transform: translate(-25%, 0);
  }
  100% {
      transform: translate(-75%, 0);
  }
}