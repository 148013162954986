@use "../abstracts" as *;

/* lps_parts--flow
********************************************** */
.lps_parts--hikaku {
  .hikaku-card-item {
    background-color: var(--clr-wht);
  }
  .hikaku-up {
    background-color: var(--clr-sub-01);
    color: var(--clr-wht);
    padding: rem(24);
    position: relative;
    height: rem(150);
    line-height: 1.8;
    &::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-top: rem(21) solid var(--clr-sub-01);
      border-left: rem(26) solid transparent;
      border-right: rem(26) solid transparent;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .hikaku-card-num {
    font-size: calc-fz(14);
  }
  .hikaku-card-up {
    font-size: rem(18);
  }
  .hikaku-card-down {
    padding: rem(48) rem(24) rem(24) rem(24);
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}
