@use "../abstracts" as *;

/* ##############################################################################

    ブロックエディタ・クラシックエディタ共通

############################################################################## */
.editor-styles-wrapper,
.mce-content-body {
  &::before,
  &::after {
    content: "";
    display: table;
  }
  &::after {
    clear: both;
  }
  ul:where(:not([class*="ez-toc-"])),
  ol {
    margin-inline-start: rem($list-start);
    &:not(:first-child) {
      margin-top: 1em;
    }
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
  ol:not([style]) {
    margin-inline-start: rem(18);
  }
  ol {
    list-style-type: decimal-leading-zero;
  }
  li {
    position: relative;
    padding-block-end: 0.5em;
  }
  ul:where(:not([class*="ez-toc-"])) li {
    list-style: none;
    &::before {
      content: "";
      float: inline-start;
      inline-size: rem($list-dot);
      aspect-ratio: 1;
      margin-block: calc((1lh - rem($list-dot)) / 2);
      margin-inline-start: calc(rem($list-start) * -1);
      color: var(--clr-primary-default);
      border: 1px solid;
      background-color: currentColor;
    }
  }
  ol li {
    &::marker {
      color: var(--clr-primary-default);
      font-weight: 600;
      letter-spacing: perc(3.2, 16, em);
    }
  }
  ol:not([style]) li {
    padding-left: rem(8);
    &::marker {
      letter-spacing: -0.02em;
    }
  }
  ul[style="list-style-type: circle;"] li::before {
    background-color: var(--clr-wht);
  }
  ul:not([style="list-style-type: square;"]) li::before {
    border-radius: 50%;
  }
  .wp-block-image img {
    margin: 1em 0;
    max-width: 100%;
    height: auto;
  }
  .wp-caption {
    width: fit-content;
    max-width: 100%;
  }
  hr {
    margin-top: rem(32);
    margin-bottom: rem(32);
    border: none;
    border-bottom: 2px solid var(--clr-primary-default);
  }
  a .wp-block-image img {
    transition: var(--transit-default);
  }
  a:hover .wp-block-image img {
    opacity: 0.8;
  }
  img {
    display: block;
  }
  .alignright,
  .alignleft {
    @include mq(sp) {
      width: 100% !important;
      float: none;
      margin: 1em auto;
      display: block;
    }
  }
  .alignleft {
    float: left;
    clear: left;
    margin-right: 1em;
    margin-bottom: 1em;
  }
  .alignright {
    float: right;
    clear: right;
    margin-left: 1em;
    margin-bottom: 1em;
  }
  .aligncenter {
    @include auto-margin;
    margin-bottom: 1em;
  }
  .wp-block-image img.aligncenter {
    display: block;
  }
  .alignleft:last-child,
  .alignright:last-child,
  .aligncenter:last-child {
    margin-bottom: 0;
  }
  p:not(:last-child) {
    margin-bottom: 1em;
  }
  strong {
    font-weight: bold;
  }
  blockquote {
    margin: 1em 0;
    padding: rem(16) rem(40);
    border-radius: rem(3);
    position: relative;
    display: inline-block;
    font-style: oblique;
    color: #666;
    background-color: var(--clr-bg-02);
    text-align: left;
    @include mq(sp) {
      padding: rem(16);
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 1em;
      height: 1em;
      opacity: 0.25;
    }
    &::before {
      background-image: url("../images/common/icon-quote_left.svg");
      top: rem(14);
      left: rem(12);
      @include mq(sp) {
        top: 0;
        left: 0;
      }
    }
    &::after {
      background-image: url("../images/common/icon-quote_right.svg");
      bottom: rem(14);
      right: rem(12);
      @include mq(sp) {
        bottom: 0;
        right: 0;
      }
    }
  }
  blockquote p {
    margin-bottom: 0;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  table {
    border: none;
    height: auto !important;
    @include mq(sp) {
      max-width: none !important;
    }
    &:not(:first-child) {
      margin-top: 1em;
    }
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
  tr,
  th,
  td {
    height: auto !important;
  }
  th,
  td {
    padding: 1em;
    border: 1px solid #d3d3d3;
    vertical-align: top;
    @include mq(sp) {
      padding: 0.75em 1em;
      line-height: 1.5;
    }
  }
  th {
    min-width: rem(120);
    color: var(--clr-sub-01);
    font-family: var(--font-primary-b);
    text-align: left;
    @include mq(sp) {
      font-weight: bold;
    }
  }
  sup {
    font-size: calc-fz(12);
    color: #555;
  }
  iframe {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    vertical-align: bottom;
    @include mq(sp) {
      &[src*="google.com/maps"] {
        aspect-ratio: 4/3;
      }
    }
    &:not(:first-child) {
      margin-top: 2em;
    }
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }
}

/* ボックス */
.mce-box,
.is-style-mce-box {
  padding: rem(24) rem(32);
  background-color: var(--clr-bg-01);
  box-shadow: 0 0 rem(14) 0 rgba(0, 0, 0, 0.05);
  @include mq(sp) {
    padding: rem(24);
  }
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
  *:not([class*="clr-"]):not([class*="bold"]):not([class*="mce-ttl"]) {
    color: var(--clr-text-primary);
  }
}
.bg-wht .mce-box {
  background-color: var(--clr-bg-03);
}
.lps_sec:nth-child(even),
.lps_sec[style*="background-color"] {
  *:not(.bg-wht) .mce-box {
    background-color: var(--clr-wht);
  }
  .bg-wht .mce-box {
    background-color: var(--clr-bg-03);
  }
}

/* リスト */
.mce-list-circle::before,
.editor-styles-wrapper ul .is-style-mce-list-circle::before {
  background-color: transparent;
}
.mce-list-square::before,
.editor-styles-wrapper ul .is-style-mce-list-square::before {
  border-radius: 0;
}
ul.mce-check--list li,
.mce-list-check,
.editor-styles-wrapper ul .is-style-mce-list-check {
  margin-inline-start: rem($list-start * 0.3);
  &::before,
  &::after {
    content: "";
    margin-inline-start: calc($check-start-em * -1);
  }
  &::before {
    width: $check-dot-em;
    margin-block: calc((1lh - $check-dot-em) / 2);
    background-color: var(--clr-sub-04);
    border-radius: 50%;
  }
  &::after {
    position: absolute;
    top: 0;
    left: calc(($check-dot-em - $check-em) / 2);
    float: inline-start;
    width: $check-em;
    margin-block: calc((1lh - $check-em) / 2);
    aspect-ratio: 10/7;
    border-left: 2px solid var(--clr-wht);
    border-bottom: 2px solid var(--clr-wht);
    rotate: -45deg;
  }
}

.mce-list-col2 {
  gap: rem(16);
  ul:not(:last-child) {
    margin-bottom: 0;
  }
  ul:not(:first-child) {
    margin-top: 0;
  }
  @include mq(sp) {
    gap: 0;
  }
}

@include mq(sp) {
  /* テーブル */
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-table1.table-sp-block td::before {
    display: none;
  }
  .mce-table2.table-sp-block th,
  .mce-table2.table-sp-block td {
    border-bottom: 0;
  }
  .mce-table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: solid 1px #84a5c0;
  }
}

.bold {
  font-family: var(--font-primary-b);
  color: var(--clr-sub-04);
}

/* テーブル
********************************************** */
.mce-table1 th,
.mce-table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
  @include mq(sp) {
    padding: 0.75em 0.25em;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: calc(100% - rem(3));
  }
}
.mce-table1 th {
  color: var(--clr-sub-01);
  &::before {
    left: 0;
    border-bottom: 2px solid;
  }
}
.mce-table1 td::before {
  right: 0;
  border-bottom: 1px solid #d3d3d3;
}
.mce-table2 {
  background-color: var(--clr-wht);
  border: solid 1px #056fbd;
  th,
  td {
    border: solid 1px #84a5c0;
  }
  @include mq(sp, min, ps) {
    th:not(:last-child) {
      border-right: 0;
    }
    td:not(:first-child) {
      border-left: 0;
    }
  }
}
.mce-table2 th {
  background-color: rgba($color: #0081cc, $alpha: 0.2);
}
@include mq(sp) {
  /* table scroll */
  .table-sp-scroll {
    min-width: sprem(540);
  }
  .scroll {
    overflow-x: scroll;
    margin-bottom: 1em;
    -webkit-overflow-scrolling: touch;
  }
  .scroll table {
    margin: 0;
  }
  .scroll--cap {
    text-align: right;
    margin: 1em 0 0 !important;
    opacity: 0.25;
    font-size: calc-fz(14);
  }
}

/* ##############################################################################

    クラシックエディタ

############################################################################## */
/* AddQuicktag
*************************************************** */
/* 見出し */
.mce-ttl-01,
.mce-ttl-02,
.mce-ttl-03,
.mce-ttl,
.mce-ttl2,
.mce-ttl3 {
  font-family: var(--font-jp);
  &:not(:first-child) {
    margin-top: 2em;
    @include mq(sp) {
      margin-top: 1.5em;
    }
  }
  &:not(:last-child) {
    margin-bottom: 1rem;
    @include mq(sp) {
      margin-bottom: 0.75rem;
    }
  }
}
.mce-ttl-01,
.mce-ttl {
  font-size: rem(40);
  letter-spacing: -0.08em;
  @include mq(sp) {
    padding: sprem(8) sprem(16);
    font-size: sprem(20) !important;
  }
  .bold {
    font-family: var(--font-jp);
  }
}
.mce-ttl-02,
.mce-ttl2 {
  font-size: calc-fz(32);
  letter-spacing: -0.08em;
  color: var(--clr-primary-dark);
  @include mq(sp) {
    padding: sprem(8) sprem(4);
    font-size: sprem(18) !important;
  }
}
.mce-ttl-03,
.mce-ttl3 {
  font-size: calc-fz(24);
  letter-spacing: -0.08em;
  color: var(--clr-sub-01);
  @include mq(sp) {
    font-size: sprem(16) !important;
  }
}
/* 注意書き */
.mce-txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: calc-fz(14);
  opacity: 0.6;
  @include mq(sp) {
    font-size: sprem(13);
  }
  &::before {
    content: "※";
  }
}

/* ##############################################################################

    ブロックエディタ 基本

############################################################################## */
.editor-styles-wrapper {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:first-child) {
      margin-top: 2em;
      @include mq(sp) {
        margin-top: 1.5em;
      }
    }
    &:not(:last-child) {
      margin-bottom: 1em;
      @include mq(sp) {
        margin-bottom: 0.75em;
      }
    }
  }
  h1,
  h2 {
    font-size: rem(32);
    @include mq(sp) {
      font-size: sprem(26);
    }
  }
  h3 {
    font-size: calc-fz(26);
    @include mq(sp) {
      font-size: sprem(22);
    }
  }
  h4 {
    font-size: calc-fz(20);
    @include mq(sp) {
      font-size: sprem(18);
    }
  }
  h5 {
    font-size: calc-fz(18);
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
  h6 {
    font-size: calc-fz(17);
    @include mq(sp) {
      font-size: sprem(15);
    }
  }
  .bold {
    background: linear-gradient(transparent 50%, #ebff91 50%);
  }
}

/* コアブロック
********************************************** */
div[class*="wp-block-"] {
  margin-block: rem(24);
  @include mq(sp) {
    gap: sprem(16);
  }
  div[class*="wp-block-"] {
    margin-block: 0 !important;
  }
}
.has-text-align-left {
  text-align: left;
}
.has-text-align-center {
  text-align: center;
}
.has-text-align-right {
  text-align: right;
}
.em {
  font-style: italic;
}
.wp-block-columns {
  display: flex;
  gap: rem(24);
  @include mq(sp) {
    gap: sprem(16);
  }
}
.wp-block-column {
  flex: 1;
  @include mq(sp) {
    flex-basis: 100% !important;
  }
  img {
    margin: 0 !important;
  }
}

/* デフォルトの機能
********************************************** */
.wp-block-table .has-fixed-layout {
  table-layout: fixed;
  width: 100%;
}
.wp-block-table.aligncenter table,
.wp-block-table.alignleft table,
.wp-block-table.alignright table {
  display: table;
  width: auto;
}
.wp-block[data-align="center"] > .wp-block-table table {
  margin: 0 auto;
}
/* 管理画面エディタ用
*************************************************** */
#tinymce {
  padding: 24px;
}
h1.editor-post-title__input {
  padding: 32px 0;
  font-size: rem(40);
  font-family: var(--font-jp);
}
.editor-styles-wrapper {
  font-size: 16px;
}

@include mq(sp) {
  .editor-styles-wrapper table tr *:first-child:nth-last-child(2),
  .editor-styles-wrapper table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}

/* ##############################################################################

    ブログ・課題解決用スタイル

    ・ブログ→ブロックエディタ
    ・課題解決→クラシックエディタ
    となっているので、適切に当たるように下記2種類書く必要がある。
    
    ・ブロックエディタ用にタグ
    ・クラシックエディタ用にclass（Add quicktag側で該当投稿だけ許可する）

############################################################################## */

// .editor-styles-wrapperは、管理画面も網羅するためにこうなってる
// h1を不許可にできないので、とりあえずh2と同じ見た目を当てとく
body:is(.post-type-blog, .post-type-case) .editor-styles-wrapper :is(h1:not(.editor-post-title), h2),
.mce-ttl-01-blog {
  font-family: var(--font-primary);
  padding: rem(8) rem(24);
  font-size: rem(40) !important;
  letter-spacing: perc(-3.2, 40, em);
  color: var(--clr-primary-dark);
  border-left: rem(8) solid var(--clr-primary-default);
  background: var(--clr-bg-03);
  @include mq(sp) {
    font-size: sprem(22) !important;
  }
}
body:is(.post-type-blog, .post-type-case) .editor-styles-wrapper h3,
.mce-ttl-02-blog {
  font-family: var(--font-primary);
  padding: rem(8);
  font-size: rem(32) !important;
  letter-spacing: perc(-2.56, 32, em);
  line-height: 1.6;
  color: var(--clr-primary-default);
  border-bottom: solid 1px;
  @include mq(sp) {
    font-size: sprem(21) !important;
  }
}
body:is(.post-type-blog, .post-type-case) .editor-styles-wrapper h4,
.mce-ttl-03-blog {
  font-family: var(--font-primary);
  padding-left: rem(16);
  font-size: rem(24) !important;
  letter-spacing: perc(-1.92, 24, em);
  line-height: 1.6;
  color: var(--clr-primary-default);
  border-left: solid rem(4);
  @include mq(sp) {
    font-size: sprem(20) !important;
  }
}
