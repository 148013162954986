@use "../abstracts" as *;

/* lps_parts--scroll_card
********************************************** */
.lps_parts--scroll_card {
  overflow: hidden;
  .lps_parts--child {
    @include mq(2240) {
      margin-left: 0;
    }
    @include mq(med) {
      margin-left: auto;
    }
  }

  .swiper-slide .img {
    @include mq(sp, min, ps) {
      width: rem(360);
    }
    @include mq(sp) {
      height: rem(240);
      img {
        object-position: top center;
      }
    }
  }
  .swiper-slide .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--clr-bg-01);
    height: auto !important;
    padding: rem(40) rem(44);
    @include mq(med) {
      padding: sprem(30) sprem(28);
    }
    *:not(.subtxt):not(.ttl-03):not(th):not(.mce-ttl-01):not(.mce-ttl-02):not(.mce-ttl-03) {
      color: var(--clr-text-primary) !important;
    }
  }
  .ttl-04 {
    color: var(--clr-primary-dark);
  }
}

/* scroll-hint
********************************************** */
@include mq(sp) {
  .scroll-hint-icon {
    top: sprem(80);
    z-index: 10;
  }
}
