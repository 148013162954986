@use "../abstracts" as *;

/* ##############################################################################

    PAGE

############################################################################## */

/* parts
********************************************** */

@include mq(sp) {
  body #section-06 .lps_parts--img_text .img_text--child .img img {
    width: 60% !important;
  }
}

/* catalog-download
********************************************** */
#autozip {
  display: none !important;
}
.catalogs_list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(100% / 6), 1fr));
  gap: rem(24) rem(12);
  @include mq(sp) {
    grid-template-columns: repeat(1, 1fr);
  }
  .catalog {
    display: grid;
    align-items: flex-start;
    grid-row: span 2;
    grid-template-rows: subgrid;
    gap: 0;
    padding: rem(16);
    background-color: var(--clr-wht);
    border: solid rem(6) var(--clr-wht);
    cursor: pointer;
    transition: var(--transit-default);

    &:hover,
    &:has(:checked) {
      background-color: #f1f4fa;
    }
    &:has(:checked) {
      border-color: var(--clr-sub-01);
      box-shadow: 0 0 rem(24) 0 #fff;
      .check--icn {
        border-color: var(--clr-sub-01);
        background-color: var(--clr-sub-01);
      }
    }
    input {
      display: none;
    }
  }
  .ttl {
    font-size: rem(20);
    line-height: 1.5;
    letter-spacing: perc(-0.4, 20, em);
    text-align: center;
  }
  .txt {
    font-size: rem(14);
  }
  .check {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: rem(24);
    padding: rem(18);
    font-size: rem(18);
    color: var(--clr-primary-dark);
    background-color: var(--clr-wht);
    border: 1px solid rgba(0, 108, 224, 0.5);
    border-radius: 9999px;
    line-height: 1;
  }
  .check--icn {
    position: relative;
    display: inline-block;
    width: 1.125em;
    height: 1.125em;
    margin-right: 0.7em;
    border: solid 1px var(--clr-sub-03);
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      margin: auto;
      display: block;
      width: 0.45em;
      height: 0.7em;
      border-bottom: solid 2px var(--clr-wht);
      border-right: solid 2px var(--clr-wht);
      transform: translateY(-25%) rotate(45deg);
    }
  }
}
@include mq(sp) {
  .catalogs_btn_area {
    position: fixed;
    inset-inline: 0;
    bottom: 0;
    z-index: 99999;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: sprem(8) sprem(28);
    background-color: var(--clr-wht);
    box-shadow: 0 0 40px rgb(0 0 0 / 20%);
    .btn button {
      width: sprem(230);
      padding: sprem(10);
      padding-right: sprem(30);
      font-size: sprem(14);
    }
  }
}
.page-catalog-download {
  .mce-box {
    padding: rem(40) rem(64);
    @include mq(sp) {
      padding: sprem(28);
      font-size: sprem(15);
    }
  }
  @include mq(sp) {
    main {
      position: relative;
      z-index: 11;
    }
    .footer {
      padding-bottom: sprem(110);
    }
  }
}

/* related_page
********************************************** */
.related_page--list {
  position: relative;
  overflow: hidden;
  z-index: 1;
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 3), 1fr));
  }
}
.related_page--list li {
  &:nth-child(9n-1) a::before,
  &:nth-child(9n-3) a::before,
  &:nth-child(9n-8) a::before {
    filter: brightness(1.1);
  }
  &:nth-child(9n-2) a::before,
  &:nth-child(9n-4) a::before,
  &:nth-child(9n-6) a::before {
    filter: brightness(0.9);
  }
}
.related_page--list a {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem(64) rem(88) rem(64) rem(64);
  line-height: var(--line-height-hdr);
  color: var(--clr-wht);
  @include mq(sp, min, ps) {
    height: rem(280);
  }
  @include mq(sp) {
    padding: sprem(40) sprem(32);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  &::before {
    inset: 0;
    pointer-events: none;
    z-index: -1;
    background-color: var(--clr-primary-default);
    transition: var(--transit-default);
  }
  .arrow {
    position: absolute;
    top: 0;
    right: rem(32);
    bottom: 0;
    margin: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include rect(88);
    border: solid 1px;
    background-color: transparent;
    @include mq(sp) {
      transform: scale(0.6, 0.6);
      transform-origin: center right;
    }
    &::after {
      content: "";
      display: inline-block;
      @include rect(8);
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
    }
  }
  &:hover::before {
    opacity: 0;
  }
  &:hover .arrow {
    background-color: var(--clr-wht);
    &::after {
      border-color: var(--clr-primary-dark);
    }
  }
}
.related_page--list .font-jp {
  margin-bottom: 0.25em;
  font-weight: 500;
  letter-spacing: perc(-1.92, 32, em);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.related_page--list .font-en {
  opacity: 0.5;
  text-transform: Capitalize;
  letter-spacing: perc(-0.96, 16, em);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
.related_page--bg {
  position: absolute;
  inset: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.2;
  z-index: -1;
}
