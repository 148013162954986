@use '../abstracts' as *;

.swiper-wrapper:has(+ .slider__scrollbar) {
  @include mq(sp) {
    margin-bottom: sprem(16);
  }
}
.slider__scrollbar {
  margin-inline: auto;
  width: 100%;
  height: 100%;
  position: relative;
  padding: rem(5) rem(5) rem(10) rem(5);
  border-radius: 999px;
  &::before {
    content: "";
    display: block;
    width: calc(100% - rem(5));
    height: rem(5);
    background-color: rgba($color: #2F3439, $alpha: .2);
    border-radius: 999px;
    position: absolute;
    top: 50%;
    left: rem(5);
    transform: translateY(-100%);
  }
}
.slider__btn {
  position: relative;
  width: rem(32);
  height: rem(20);
  background-color: var(--clr-wht);
  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.05);
  border-radius: 999px;
  transition: var(--transit-default);
  cursor: pointer;
  @include mq(sp) {
    height: sprem(10);
  }
}