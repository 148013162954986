@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 15;
$wid-pc: 1920;
$wid-sp: 414;
$wid-var: 1800; // フォントサイズは画面幅がこの値～$wid-pcで可変する
$header-hgt: 120;
$header-hgt-sp: 64;
$header-hgt-fx: 100;
$list-dot: 10; // リストのドットサイズ
$list-gap: 16; // リストの余白
$list-start: $list-dot + $list-gap;
$check: 10; // チェックリストのチェックのサイズ
$check-dot: 24; // チェックリストのドットサイズ
$check-em: calc($check / $fz-pc) * 1em;
$check-dot-em: calc($check-dot / $fz-pc) * 1em;
$check-start-em: calc($check-em + $check-dot-em);
$flow-dot: 24; // 流れのドットサイズ
$flow-num-dot: 64; // 流れ（連番）のドットサイズ
$flow-gap: 32; // 流れの余白
$flow-bd: 3; // 流れのボーダーサイズ
$flow-bd-gap: 6; // 流れのボーダー余白
$flow-start: $flow-num-dot + $flow-gap;
$flow-dot-sp: 16; // 流れのドットサイズ（SP）
$flow-num-dot-sp: 40; // 流れ（連番）のドットサイズ（SP）
$flow-gap-sp: 16; // 流れの余白（SP）
$flow-bd-sp: 2; // 流れのボーダーサイズ（SP）
$flow-bd-gap-sp: 4; // 流れのボーダー余白
$flow-start-sp: $flow-num-dot-sp + $flow-gap-sp;

// カラー管理
$colors: (
  clr-primary-default: #004ea2,
  clr-primary-dark: #002b59,
  clr-primary-light: #006ce0,

  clr-secondary-default: #adf515,
  clr-secondary-dark: #8ac906,
  clr-secondary-light: #8ac906,

  clr-bg-01: #fcfeff,
  clr-bg-02: #f0efec,
  clr-bg-03: #f0f6f9,
  clr-bg-04: #deecf6,

  clr-sub-05: #0020f2,
  clr-sub-04: #1209af,
  clr-sub-03: #becee6,
  clr-sub-02: #130341,
  clr-sub-01: #0081cc,
  clr-tag-hover: #cee5f3,

  clr-mono-06: #333841,
  clr-mono-05: #646b7a,
  clr-mono-04: #9599a3,
  clr-mono-03: #babdc4,
  clr-mono-02: #dfdfe0,
  clr-mono-01: #f2f2f2,

  clr-text-primary: #130341,
  clr-text-secondary: #7a7a81,
  clr-text-placeholder: #ccc,
  clr-text-light: #f7f7f7,

  clr-wht: #fff,
  clr-blk: #000,
);

// CSS変数
:root {
  // typography
  --ltr-space-default: 0.03em;
  --line-height-default: 2;
  --line-height-hdr: 1.6;
  --line-height-none: 1;

  // font-families
  --font-default: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif; // webフォントを使わない場合
  --font-primary: YakuHanJP, "Aoto Gothic DemiBold", sans-serif; // 本文。txt-md-jp
  --font-primary-r: YakuHanJP, "Aoto Gothic Regular", sans-serif;
  --font-primary-b: YakuHanJP, "Aoto Gothic Bold", sans-serif;
  --font-jp: YakuHanMP, "Zen Old Mincho", serif; // title周りで使うfont。足りなければ増やす
  --font-en: "Poppins", sans-serif;
  --font-en2: "Roboto Condensed", sans-serif;

  //カラーを出力
  @each $key, $color in $colors {
    --#{$key}: #{$color};
  }

  // animation
  --transit-default: all 0.4s ease-out;
}

// SASS Maps

$z-index: (
  "header": 9999,
  "top": 9990,
  "aside": 75,
  "middle": 50,
  "main": 1,
  "base": 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160,
  // 2560px
  lg: 120,
  // 1920px
  pc: 85.375,
  // 1366px
  med: 67.5,
  // 1080px
  sp: 47.9375,
  // 767px
  sm: 40,
  // 640px
  xs: 34.375,
  // 500px
  se: 21.25,
  // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$aspect-ratios: (
  widescreen: "3/2",
  square: "1/1",
  portrait: "2/3",
);
