@use "../abstracts" as *;

/* page_ttl
********************************************** */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
  padding-top: rem(136);
  @include mq(sp, min, ps) {
    .txtarea {
      width: rem(640);
    }
  }
  @include mq(sp) {
    padding-top: sprem(40);
    .txtarea {
      margin-bottom: sprem(32);
    }
  }
}
.page_ttl-jp {
  display: block;
  text-transform: capitalize;
  font-size: calc-fz(88);
  color: var(--clr-primary-dark);
  line-height: 1.2;
  letter-spacing: perc(-5.28, 88, em);
  margin-bottom: rem(36);
  @include mq(sp) {
    font-size: sprem(32);
    margin-bottom: sprem(18);
  }
}
.page_ttl-en {
  display: block;
  font-size: rem(24);
  letter-spacing: perc(-1.44, 24, em);
  margin-bottom: rem(32);
  font-weight: 500;
  opacity: 0.5;
  color: var(--clr-primary-default);
  @include mq(sp) {
    font-size: sprem(12);
    margin-bottom: sprem(16);
  }
}
.page_txt {
  font-size: rem(20);
  line-height: 2.1;
  max-width: rem(650);
  @include mq(sp) {
    margin-top: sprem(14);
    margin-bottom: sprem(16);
    font-size: sprem(14);
  }
  a {
    text-decoration: underline;
  }
}
.page_ttl-list {
  font-size: rem(20);
  margin-inline-start: rem($list-start * 1.35);
  @include mq(sp) {
    font-size: sprem(14);
    margin-inline-start: rem($list-start);
  }
  li {
    position: relative;
    padding-block-end: 0.5em;
    list-style: none;
    margin-inline-start: rem($list-start * 0.3);
    &::before {
      float: inline-start;
      inline-size: rem($list-dot);
      aspect-ratio: 1;
      color: var(--clr-primary-default);
      border: 1px solid;
    }
  }
}
.page_txt + .page_ttl-list {
  margin-top: rem(32);
  @include mq(sp) {
    margin-top: sprem(16);
  }
}
* + .page_ttl-cta {
  margin-top: rem(60);
  @include mq(sp) {
    margin-top: sprem(30);
  }
}
.page_ttl--contact {
  @include mq(sp, min) {
    min-width: rem(252);
  }
}
.page_ttl .scroll-img {
  margin-top: rem(46);
  @include mq(sp) {
    margin-top: sprem(23);
  }
}
@include mq(sp) {
  .page_ttl-cta {
    position: fixed;
    bottom: 0;
    inset-inline: calc(50% - 50vw);
    li {
      flex-grow: 1;
    }
    a {
      width: 100%;
      height: 100%;
      padding: 1.3em 0.5em;
      font-size: sprem(14);
    }
  }
}

/* ttl
********************************************** */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04,
.ttl-05,
.ttl-06 {
  position: relative;
  letter-spacing: 0.1em;
  span {
    line-height: inherit;
    letter-spacing: -0.05em;
  }
}
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04,
.ttl-05,
.ttl-06 {
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 0.5em;
  }
}
.ttl-01 {
  color: var(--clr-primary-dark);
  font-size: rem(64);
  @include mq(med) {
    font-size: rem(72);
  }
  @include mq(sp) {
    font-size: sprem(32);
  }
  &.font-en {
    font-size: rem(100);
    letter-spacing: -0.03em;
    @include mq(sp) {
      font-size: sprem(50);
    }
  }
  &.clr-wht .subtitle {
    color: var(--clr-wht);
    &::after {
      background-color: var(--clr-wht);
    }
  }
  &.deco {
    padding-bottom: rem(34);
    position: relative;
    @include mq(sp) {
      padding-bottom: sprem(18);
    }
    &::before {
      content: "";
      width: rem(30);
      aspect-ratio: 30 / 10;
      background-image: url(../images/common/ttl-deco.svg);
      background-size: cover;
      position: absolute;
      left: 0;
      bottom: 0;
      @include mq(sp) {
        width: sprem(20);
      }
    }
  }
  .subtitle {
    margin-top: rem(32);
    @include mq(sp) {
      margin-top: sprem(8);
      line-height: 1.5;
    }
    &:not(.font-jp) {
      font-family: var(--font-en), var(--font-primary);
    }
    &.font-jp {
      font-weight: 600;
      letter-spacing: perc(-0.72, 24, em) !important;
    }
  }
}
.ttl-01,
.hero--ttl {
  .animation {
    position: relative;
    z-index: 1;
    display: block;
    line-height: 1.25;
    width: fit-content;
    &:nth-child(2) {
      z-index: 10;
      margin-top: -1.25em;
      color: transparent;
      -webkit-background-clip: text;
      background-image: linear-gradient(90deg, rgba(175, 45, 115, 0) 0%, #004ea2 29%, #004ea2 58.5%, #30a9ce 79.5%, #adf515 87.5%, #0068b7 89.5%, rgba(16, 35, 180, 0) 100%);
      background-size: 200%;
      background-position: 200%;
      background-repeat: no-repeat;
      transition: background-position 4s ease-out;
    }
  }
  &.active .animation:nth-child(2) {
    background-position: -100%;
  }
  .animation-reverse {
    &:nth-child(2) {
      background-position: -100%;
      background-image: linear-gradient(90deg, rgba(16, 35, 180, 0) 0%, #0068b7 10.5%, #adf515 12.5%, #30a9ce 20.5%, #004ea2 41.5%, #004ea2 70%, rgba(175, 45, 115, 0) 100%);
    }
  }
  &.active .animation-reverse:nth-child(2) {
    background-position: 200%;
  }
  .animation + .subtitle {
    margin-top: 0;
  }
}
.ttl-01.txt-ctr .animation {
  margin-inline: auto;
}
.ttl-01-lg {
  font-size: rem(120) !important;
  @include mq(med) {
    font-size: rem(80) !important;
  }
  @include mq(sp) {
    font-size: sprem(60) !important;
  }
  .subtitle {
    font-size: rem(24);
    @include mq(sp) {
      font-size: sprem(19);
    }
  }
}
.ttl-02 {
  font-size: rem(48);
  letter-spacing: -0.06em;
  line-height: 1.6;
  @include mq(sp) {
    font-size: sprem(28);
    line-height: 1.3;
  }
  &.font-en {
    font-size: rem(48);
    @include mq(sp) {
      font-size: sprem(30);
    }
  }
  .ttl-02-sub {
    font-size: rem(16);
    font-family: var(--font-en);
    font-weight: 600;
    letter-spacing: -0.03em;
    line-height: 1.6;
    @include mq(sp) {
      margin-top: sprem(4);
      font-size: sprem(14);
    }
  }
}
.subpage .ttl-02 {
  font-size: rem(40);
  @include mq(sp) {
    font-size: sprem(20);
  }
  &.font-en {
    font-size: rem(40);
    @include mq(sp) {
      font-size: sprem(20);
    }
  }
}
.ttl-03 {
  font-size: rem(40);
  letter-spacing: -0.06em;
  @include mq(sp) {
    font-size: sprem(24);
  }
  &.deco {
    padding-bottom: rem(34);
    position: relative;
    @include mq(sp) {
      padding-bottom: sprem(20);
    }
    &::before {
      content: "";
      width: rem(30);
      height: rem(10);
      background-image: url(../images/common/ttl-deco.svg);
      background-size: cover;
      position: absolute;
      left: 0;
      bottom: 0;
      @include mq(sp) {
        width: calc(sprem(30) / 3 * 2);
        height: calc(sprem(10) / 3 * 2);
      }
    }
  }
  .subtitle {
    opacity: 0.5;
    letter-spacing: perc(-0.96, 16, em);
    margin-top: rem(8);
    @include mq(sp) {
      margin-top: sprem(8);
    }
  }
}
.subpage .ttl-03 {
  @include mq(sp, min, ps) {
    font-size: rem(32);
  }
  span span {
    color: var(--clr-sub-04);
  }
}
.subpage.single h1.ttl-03 {
  font-size: rem(56);
  @include mq(sp) {
    font-size: sprem(32);
  }
}
.ttl-04 {
  font-size: rem(28);
  letter-spacing: -0.01em;
  @include mq(sp) {
    font-size: sprem(20);
  }
  span span {
    color: var(--clr-sub-04);
  }
}
.ttl-05 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(20);
  }
  &.font-en {
    font-size: rem(24);
    @include mq(sp) {
      font-size: sprem(20);
    }
  }
}
.ttl-06 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(20);
  }
  &.font-en {
    font-size: rem(20);
    @include mq(sp) {
      font-size: sprem(18);
    }
  }
}
.subtxt {
  color: var(--clr-sub-01);
  font-weight: 600;
  font-size: rem(18);
  letter-spacing: -0.01em;
  line-height: 1.6;
  @include mq(sp) {
    font-size: sprem(14);
  }
}

/* subtitle
********************************************** */
.subtitle,
.subtitle-sm {
  letter-spacing: 0.03em !important;
}
.subtitle {
  display: block;
  font-size: calc-fz(20);
  @include mq(sp) {
    font-size: sprem(16);
  }
  &.font-en {
    font-size: rem(18);
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
  &.txt-ctr::after {
    @include auto-margin;
  }
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: perc(48, 16, em);
  }
}
.subtitle-sm {
  display: block;
  font-size: calc-fz(14);
  @include mq(sp) {
    font-size: sprem(12);
  }
  &.font-en {
    font-size: rem(14);
    @include mq(sp) {
      font-size: sprem(12);
    }
  }
}
.kazari-en {
  font-size: rem(152);
  @include mq(sp) {
    font-size: sprem(56);
  }
}
