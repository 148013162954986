@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
.breadcrumbs .inner {
  padding: rem(16) 0;
  white-space: nowrap;
  @include mq(sp, min, ps) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @include mq(sp) {
    overflow: auto;
  }
}
.breadcrumbs a:hover,
.breadcrumbs span {
  color: var(--clr-primary-default);
}
.breadcrumbs span span:not(:last-child)::after {
  content: '';
  display: inline-block;
  width: rem(6);
  height: rem(6);
  border-top: 1px solid var(--clr-primary-dark);
  border-right: 1px solid var(--clr-primary-dark);
  transform: rotate(45deg);
  margin: .15em .5em .15em 1em;
}