@use "../abstracts" as *;

/* ##############################################################################

  FORM

############################################################################## */
.form_wrap {
  padding: rem(70) rem(80);
  background-color: var(--clr-wht);
  @include mq(sp) {
    padding: sprem(20) sprem(16);
  }
  a {
    color: var(--clr-sub-01);
    text-decoration: underline;
  }
}
.nf-form-cont * {
  font-family: var(--font-default) !important;
}
.nf-form-cont .nf-form-content label,
.nf-form-cont .nf-field-label .nf-label-span {
  font-size: calc-fz(18);
  @include mq(sp) {
    font-size: sprem(15);
    margin-bottom: 6px !important;
  }
}
.nf-form-cont .nf-form-fields-required {
  display: none !important;
}
#ninja_forms_required_items,
.nf-form-cont .nf-field-container {
  margin-bottom: rem(24);
  @include mq(sp) {
    margin-bottom: sprem(16);
  }
}
.nf-form-content .label-above .nf-field-label,
.nf-form-content .label-below .nf-field-label,
.nf-form-content .label-hidden .nf-field-label {
  margin-bottom: rem(4) !important;
}
.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element > div,
.nf-form-cont .nf-form-content input:not([type="button"]),
.nf-form-cont .nf-form-content textarea {
  background: var(--clr-wht);
  color: var(--clr-text-primary);
  border-radius: 0;
  vertical-align: bottom;
  font-size: calc-fz(16);
  transition: var(--transit-default, all 0.4s ease-out);
  font-weight: 500;
  border: solid 1px var(--clr-mono-02);
}
.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element > div:focus,
.nf-form-cont .nf-form-content input:not([type="button"]):focus,
.nf-form-cont .nf-form-content textarea:focus {
  color: var(--clr-text-primary);
}
.nf-form-content .list-checkbox-wrap .nf-field-element li label,
.nf-form-content .list-image-wrap .nf-field-element li label,
.nf-form-content .list-radio-wrap .nf-field-element li label {
  cursor: pointer;
}
.nf-form-content input[type="checkbox"]:focus + label:after,
.nf-form-content input[type="radio"]:focus + label:after {
  box-shadow: none;
}
.checkbox-wrap .nf-field-element label.nf-checked-label:before,
.checkbox-wrap .nf-field-label label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-element label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-label label.nf-checked-label:before {
  color: var(--clr-primary-default);
}
.nf-form-cont .ninja-forms-req-symbol {
  font-size: 0 !important;
  &::before {
    display: inline-block;
    content: "\5FC5\9808"; /* 必須 */
    background-color: #e80000;
    color: var(--clr-wht);
    margin-left: 0.5em;
    font-size: calc-fz(13);
    padding: rem(2) rem(12);
    border-radius: rem(30);
    transform: translateY(rem(-2));
  }
}
.list-checkbox-wrap .nf-field-element li label,
.list-image-wrap .nf-field-element li label,
.list-radio-wrap .nf-field-element li label {
  margin-left: 2.25em !important;
  font-weight: bold;
  color: var(--clr-text-primary);
  font-size: calc-fz(16);
  &::before {
    top: 0 !important;
    left: -1.6em !important;
    color: var(--clr-sub-01) !important;
    font-size: 1.25em !important;
  }
  &::after {
    opacity: 1 !important;
    top: 0 !important;
    left: -2em !important;
    width: 1.25em !important;
    height: 1.25em !important;
    background: var(--clr-wht) !important;
    border: solid 1px var(--clr-mono-02) !important;
    @include mq(sp) {
      top: 0.05em !important;
    }
  }
}
.listcheckbox-container .list-checkbox-wrap .nf-field-element:after {
  transform: translateY(-90%);
}
.listcheckbox-container .list-checkbox-wrap .nf-field-element ul {
  padding-top: rem(4) !important;
}
.nf-form-cont .nf-form-content .submit-wrap {
  text-align: center;
}
.nf-form-cont .nf-form-content .submit-wrap input:not([type="button"]) {
  max-width: 100%;
  @include rect(420, 68);
  font-size: rem(18);
  line-height: 1;
  letter-spacing: 0.02em;
  background-color: var(--clr-sub-01);
  color: var(--clr-wht);
  border-radius: rem(50);
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  font-weight: bold;
  transition: var(--transit-default, all 0.4s ease-out);
  @media (hover: hover) {
    &:hover {
      color: var(--clr-primary-dark);
      background-color: var(--clr-wht);
    }
  }
  @include mq(sp, min) {
    margin-top: sprem(24);
  }
}
.nf-form-cont .nf-form-errors {
  margin-top: -1em;
  text-align: center;
}

@include mq(sp, min, ps) {
}
@include mq(sp) {
}

/* リキャプチャ・キャプション
**************************************** */
.grecaptcha-badge {
  visibility: hidden;
}
.recaptcha_policy {
  padding: 0;
  margin-top: rem(48);
  color: var(--clr-text-primary);
  font-size: calc-fz(10);
  text-align: center;
  letter-spacing: 0;
  @include mq(sp) {
    text-align: left;
  }
}
.recaptcha_policy a {
  color: #69abdb;
  font-size: calc-fz(12);
  text-decoration: underline;
}
