@use "../abstracts" as *;

/* hero
********************************************** */
.hero {
  position: relative;
  padding-top: rem(16);
  @include mq(sp) {
    padding-top: sprem(8);
  }

  .section-deco-line {
    top: rem(48);
    right: 0;
    @include mq(sp) {
      top: sprem(8);
    }
  }
  .section-deco {
    left: rem(52);
    bottom: rem(270);
    transform: rotate(-90deg);
    @include mq(sp) {
      left: sprem(18);
      bottom: sprem(310);
    }
  }
  .section-deco-grid {
    bottom: 0;
    right: 0;
    transform: translateY(18%);
  }
}
.hero--ttl {
  margin-bottom: rem(28);
  font-size: rem(64);
  color: var(--clr-sub-02);
  line-height: 1.2;
  @include mq(sp) {
    margin-bottom: sprem(24);
    font-size: sprem(32);
  }
  .line {
    display: inline-block;
    &:first-child {
      transform: translateX(0.125em) scale(1.05, 1);
      opacity: 0;
      animation: line1 0.8s cubic-bezier(0.18, 0.89, 0.32, 1.1) 0.9s forwards;
      clip-path: inset(0 100% 0 0);
    }
    @keyframes line1 {
      0% {
        transform: translateX(0.125em) scale(1.05, 1);
        opacity: 0;
        clip-path: inset(0 100% 0 0);
      }
      100% {
        transform: translateX(0) scale(1, 1);
        opacity: 1;
        clip-path: inset(0 0 0 0);
      }
    }
    &:last-child {
      transform: translateX(-0.125em) scale(1.05, 1);
      opacity: 0;
      animation: line2 0.8s cubic-bezier(0.18, 0.89, 0.32, 1.1) 0.9s forwards;
      clip-path: inset(0 0 0 100%);
      @include mq(sp) {
        .animation:nth-child(2) {
          margin-top: -2.5em !important;
        }
      }
    }
    @keyframes line2 {
      0% {
        transform: translateX(-0.125em) scale(1.05, 1);
        opacity: 0;
        clip-path: inset(0 0 0 100%);
      }
      100% {
        transform: translateX(0) scale(1, 1);
        opacity: 1;
        clip-path: inset(0 0 0 0);
      }
    }
    .animation:nth-child(2) {
      transition: background-position 5s ease-out !important;
    }
    &:nth-child(1) .animation:nth-child(2) {
      margin-top: -1.86em !important;
      @include mq(sp) {
        margin-top: -1.9em !important;
      }
    }
  }
}
.hero--ttl,
.hero--ttl * {
  letter-spacing: perc(-3.84, 64, em);
}
.hero--ttl .ls-sm {
  letter-spacing: perc(-13.44, 56, em);
}
.hero--catch {
  margin-bottom: rem(64);
  font-size: rem(20);
  color: var(--clr-primary-dark);
  letter-spacing: perc(0.6, 20, em);
  line-height: 2.1;
  animation: slidein 0.7s cubic-bezier(0.08, 0.82, 0.17, 1) 1.6s forwards;
  opacity: 0;
  clip-path: inset(0 0 100% 0);
  @include mq(sp) {
    margin-bottom: sprem(36);
    font-size: sprem(16);
  }
}
@keyframes slidein {
  0% {
    transform: translateY(5%);
    opacity: 0;
    clip-path: inset(0 0 100% 0);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    clip-path: inset(0 0 -10% 0);
  }
}

/* --- hero_slides --- */
.hero_slides {
  height: rem(900);
  animation: slidein 1.2s cubic-bezier(0.08, 0.82, 0.17, 1) 2.2s forwards;
  opacity: 0;
  @include mq(sp, min, ps) {
    .pc-none {
      display: none !important;
    }
  }
  @include mq(sp) {
    height: rem(600);
    .pc-none {
      display: block !important;
    }
    .sp-none {
      display: none !important;
    }
  }
}
.hero_slide {
  margin: auto;
  width: rem(700);
  perspective: rem(5000);
  padding-top: rem(400);
  margin-top: rem(-400);
  position: relative;
  @include mq(med) {
    width: rem(466);
  }
  @include mq(sp) {
    width: sprem(180);
  }
}
.hero_slide-carrousel {
  width: 100%;
  position: absolute;
  float: right;
  animation: rotar 100s infinite linear;
  transform-style: preserve-3d;
  .img {
    position: absolute;
    width: 100%;
    height: rem(450);
    overflow: hidden;
    &:nth-child(1) {
      transform: rotateY(0deg) translateZ(rem(650));
    }
    &:nth-child(2) {
      transform: rotateY(60deg) translateZ(rem(650));
    }
    &:nth-child(3) {
      transform: rotateY(120deg) translateZ(rem(650));
    }
    &:nth-child(4) {
      transform: rotateY(180deg) translateZ(rem(650));
    }
    &:nth-child(5) {
      transform: rotateY(240deg) translateZ(rem(650));
    }
    &:nth-child(6) {
      transform: rotateY(300deg) translateZ(rem(650));
    }
    @include mq(med) {
      height: rem(300);
      &:nth-child(1) {
        transform: rotateY(0deg) translateZ(rem(433));
      }
      &:nth-child(2) {
        transform: rotateY(60deg) translateZ(rem(433));
      }
      &:nth-child(3) {
        transform: rotateY(120deg) translateZ(rem(433));
      }
      &:nth-child(4) {
        transform: rotateY(180deg) translateZ(rem(433));
      }
      &:nth-child(5) {
        transform: rotateY(240deg) translateZ(rem(433));
      }
      &:nth-child(6) {
        transform: rotateY(300deg) translateZ(rem(433));
      }
    }
    @include mq(sp) {
      height: sprem(200);
      &:nth-child(1) {
        transform: rotateY(0deg) translateZ(sprem(180));
      }
      &:nth-child(2) {
        transform: rotateY(60deg) translateZ(sprem(180));
      }
      &:nth-child(3) {
        transform: rotateY(120deg) translateZ(sprem(180));
      }
      &:nth-child(4) {
        transform: rotateY(180deg) translateZ(sprem(180));
      }
      &:nth-child(5) {
        transform: rotateY(240deg) translateZ(sprem(180));
      }
      &:nth-child(6) {
        transform: rotateY(300deg) translateZ(sprem(180));
      }
    }
  }
  img {
    image-rendering: auto;
    transition: all 300ms;
    width: 100%;
    height: 100%;
  }
}
@keyframes rotar {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

/* home_pickup
********************************************** */
.home_pickup {
  overflow: hidden;
}

/* --- pickup_slider --- */
.pickup_slider {
  margin: 0 rem(-33);
  @include mq(sp) {
    margin: 0 rem(-3);
    width: 86%;
    margin-inline: auto;
  }
  .slick-list {
    overflow: visible;
  }
  .slide {
    padding: 0 rem(11);
    @include mq(sp) {
      padding: 0 sprem(3);
    }
  }
  .link {
    display: block;
    transition: all 0.2s ease-out;
    @media (hover: hover) {
      &:hover {
        transform: scale(1.05, 1.05);
        transform-origin: center;
      }
    }
  }
}

/* home_about
********************************************** */
.home_about {
  .section-deco-grid {
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: rem(594);
  }

  .txtarea {
    max-width: rem(640);
    @include mq(sp) {
      order: 2;
    }
  }

  .ttl-01 {
    position: relative;
    padding-bottom: rem(48);
    @include mq(sp) {
      padding-bottom: sprem(24);
    }
    span {
      line-height: 1;
      font-weight: 500;
      letter-spacing: perc(-3.84, 64, em);
    }
    .subtitle {
      margin-top: 0;
      color: #3f4853;
      font-size: rem(16);
      letter-spacing: perc(-0.96, 16, em);
      opacity: 0.2;
      @include mq(sp) {
        font-size: sprem(14);
      }
    }
    &::before {
      content: "";
      width: rem(30);
      height: fit-content;
      aspect-ratio: 30 / 10;
      background-image: url(../images/common/ttl-deco.svg);
      background-size: cover;
      position: absolute;
      left: 0;
      bottom: 0;
      @include mq(sp) {
        width: sprem(20);
      }
    }
  }

  .ttl {
    width: fit-content;
    margin-bottom: rem(48);
    padding: rem(4) rem(16);
    font-size: rem(40);
    letter-spacing: perc(-2.8, 40, em);
    line-height: 1.6;
    font-weight: 500;
    color: var(--clr-wht);
    background-color: var(--clr-sub-04);
    @include mq(sp) {
      margin-bottom: sprem(26);
      font-size: sprem(24);
      padding: rem(4) rem(12);
    }
  }

  .txt {
    font-size: rem(20);
    line-height: 2.1;
    letter-spacing: perc(0.6, 20, em);
    &:not(:last-of-type) {
      margin-bottom: rem(20);
    }
    &:last-of-type {
      margin-bottom: rem(56);
    }
    @include mq(sp) {
      font-size: sprem(16);
      &:not(:last-of-type) {
        margin-bottom: sprem(16);
      }
      &:last-of-type {
        margin-bottom: sprem(16);
      }
    }
  }

  .imgarea {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(840);
    aspect-ratio: 840 / 840;
    height: fit-content;
    @include mq(sp, min, ps) {
      margin-top: rem(80);
      transform: translateX(10%);
    }
    @include mq(sp) {
      width: auto !important;
      margin-left: -7vw;
      margin-right: -7vw;
      order: 1;
      padding-bottom: sprem(16);
    }
    .copy {
      position: absolute;
      top: rem(160);
      right: rem(-40);
      color: #3f4853;
      font-size: rem(16);
      letter-spacing: perc(-0.96, 16, em);
      opacity: 0.5;
      @include mq(sp) {
        top: sprem(24);
        right: sprem(12);
        z-index: 10;
        font-size: sprem(12);
      }
    }
    .bg {
      position: absolute;
      inset: 0;
      margin: auto;
      max-width: calc(460px / 840px * 100%);
    }
    .lottie {
      width: rem(840);
      aspect-ratio: 1;
    }
  }
}

/* home_client
********************************************** */
.home_client {
  .ttl-01 .animation:nth-child(2) {
    transition: background-position 3s ease-out;
  }
  .section-deco01 {
    top: rem(100);
    right: rem(-40);
    transform: rotate(90deg);
    @include mq(sp) {
      top: sprem(70);
    }
  }
  .section-deco02 {
    left: rem(-24);
    bottom: rem(72);
    @include mq(sp) {
      display: none;
    }
  }
}
.home_client-txt {
  display: block;
  font-size: calc-fz(14);
}
.client_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: rem(8);
  // 4カラムにするならこれに戻して
  // max-width: rem(900);
  max-width: rem(800);
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    gap: sprem(6);
  }
}

/* home_business
********************************************** */
.home_business {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(rem(16));
  .business_slider-wrap {
    width: 100%;
    height: rem(590);
    overflow: hidden;
    @include mq(sp) {
      height: sprem(320);
    }
    img {
      width: 100% !important;
    }
  }

  .ttlarea {
    @include mq(sp, min, ps) {
      gap: rem(196);
    }
  }
  .ttl-01 {
    transform: translateX(rem(-8));
    white-space: nowrap;
    @include mq(sp) {
      transform: translateX(sprem(-4));
    }
    .animation {
      line-height: 0.9;
      &:nth-child(2) {
        margin-top: -1.8em;
      }
    }
  }
  .ttl {
    color: var(--clr-primary-dark);
    font-size: rem(40);
    line-height: 1.6;
    letter-spacing: perc(-1.2, 40, em);
    @include mq(sp) {
      font-size: sprem(24);
    }
  }
  .section-deco-line {
    position: static;
    display: block;
    width: calc(rem(32) * 3);
    margin-top: rem(24);
    margin-bottom: rem(44);
    background-size: 100%;
    @include mq(sp) {
      width: calc(sprem(24) * 3);
      margin-top: sprem(18);
      margin-bottom: sprem(28);
    }
  }
  .main_txt {
    max-width: rem(800);
    color: var(--clr-primary-dark);
  }
}

/* --- business_list --- */
.business_list {
  border-bottom: solid 1px var(--clr-sub-03);
  .content {
    position: relative;
    counter-increment: number;
    border-top: solid 1px var(--clr-sub-03);
    @include mq(sp) {
      padding-bottom: sprem(32);
    }
    @include mq(sp, min, ps) {
      &::after {
        content: "";
        position: absolute;
        bottom: rem(190);
        right: rem(-40);
        pointer-events: none;
        transition: var(--transit-default);
        opacity: 0;
        transform: translateY(0.5rem);
        width: rem(262);
        aspect-ratio: 262/203;
        background-image: var(--img);
        background-size: cover;
      }
    }
  }
  .link {
    position: relative;
    gap: rem(40);
    padding: rem(34) rem(48);
    overflow: hidden;
    @include mq(sp) {
      gap: sprem(14);
      padding: sprem(32) sprem(8);
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      background-color: rgba(190, 208, 231, 0.1);
      animation: hoverout_bg 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
      transform: translateX(100%);
    }
    @include mq(sp) {
      &::after {
        content: "";
        display: block;
        width: 100%;
        aspect-ratio: 968/751;
        background-image: var(--img-sp);
        background-size: cover;
      }
    }
    > .arrow {
      position: relative;
      @include rect(88);
      background-color: transparent;
      border: 1px solid var(--clr-sub-03);
      @include mq(sp) {
        display: none;
      }
      svg {
        fill: var(--clr-primary-dark);
      }
    }
  }
  @media (hover: hover) {
    .link:hover::before {
      animation: hover_bg 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) forwards;
    }
  }
  @keyframes hoverout_bg {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes hover_bg {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  .name {
    position: relative;
    width: rem(580);
    max-width: 100%;
    font-size: rem(40);
    letter-spacing: perc(-0.8, 40, em);
    font-family: var(--font-primary-r);
    margin-top: rem(16);
    @include mq(med) {
      width: rem(300);
    }
    @include mq(sp) {
      width: 100%;
      font-size: sprem(26);
      line-height: 1.3;
    }
    &::before {
      content: counter(number, decimal-leading-zero);
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      font-size: rem(18);
      letter-spacing: perc(-1.08, 18, em);
      transform: translateY(-110%);
      font-family: var(--font-en);
      font-weight: 400;
      line-height: 0.8;
      @include mq(sp) {
        transform: translateY(-150%);
      }
    }
  }
  .txtarea {
    flex: 1;
  }
  .tag {
    display: flex;
    flex-wrap: wrap;
    gap: rem(4);
    margin-bottom: rem(12);
    @include mq(sp) {
      font-size: sprem(12);
    }
    li {
      padding: rem(2) rem(10);
      color: var(--clr-primary-dark);
      background-color: var(--clr-tag-hover);
      &::before {
        content: "#";
      }
    }
  }
  @media (hover: hover) {
    .content:hover::after {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.circle {
  position: absolute;
  top: -1px;
  width: calc(50% + 1px);
  height: calc(100% + 2px);
  overflow: hidden;
  opacity: 0;
  &:nth-child(1) {
    right: -1px;
  }
  &:nth-child(2) {
    left: -1px;
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid var(--clr-primary-dark);
    transition: var(--transit-default);
    transform: rotate(-180deg);
  }
  &:nth-child(1)::before {
    top: 0;
    right: 0;
    border-left: 0;
    border-radius: 0 100% 100% 0% / 0 50% 50% 0;
    transform-origin: left;
  }
  &:nth-child(2)::before {
    bottom: 0;
    left: 0;
    border-right: 0;
    border-radius: 100% 0 0 100% / 50% 0 0 50%;
    transform-origin: right;
  }
}
.link:hover .circle,
.slick-active .circle {
  opacity: 1;
  &:nth-child(1)::before {
    animation: circle_progress 0.1s linear forwards;
  }
  &:nth-child(2)::before {
    animation: circle_progress 0.1s linear 0.1s forwards;
  }
  @keyframes circle_progress {
    0% {
      transform: rotate(-180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

/* home_case
********************************************** */
.ipad .home_case > .inner {
  grid-template-columns: 1fr rem(800);
}
.home_case {
  > .inner {
    display: grid;
    grid-template-columns: 1fr rem(990);
    @include mq(med) {
      grid-template-columns: 1fr;
    }
  }
  .ttl-01 span:not(.subtitle) {
    display: block;
    transform: translateX(rem(-8));
  }
  .ttl-01 .animation:nth-child(2) {
    transition: background-position 3s ease-out;
  }
  .postarea {
    padding: rem(64) rem(72);
    background-color: var(--clr-wht);
    @include mq(med, min, ps) {
      transform: translateX(rem(70));
    }
    @include mq(med) {
      margin-top: rem(72);
    }
    @include mq(sp) {
      margin-top: 0;
      margin-left: rem(-16);
      margin-right: rem(-16);
      padding: sprem(20) sprem(16);
      margin-bottom: sprem(24);
    }
  }
  .posts-case {
    grid-template-columns: repeat(2, 1fr);
    gap: rem(48) rem(56);
    @include mq(sp) {
      grid-template-columns: 1fr;
      gap: sprem(16);
      .post {
        display: grid;
        grid-template-columns: sprem(160) 1fr;
        gap: sprem(8);
      }
      .post--txtarea {
        padding-top: 0;
      }
    }
  }

  .ttl-02 {
    padding-top: rem(32);
    span {
      font-weight: 500;
    }
    &::before {
      content: "";
      width: rem(30);
      height: rem(10);
      background-image: url(../images/common/ttl-deco.svg);
      background-size: cover;
      position: absolute;
      top: 0;
      left: auto;
      right: auto;
    }
  }
  .section-deco01 {
    top: rem(610);
    left: rem(-100);
    @include mq(sp) {
      display: none;
    }
  }
  .section-deco02 {
    bottom: rem(230);
    right: rem(-140);
    transform: rotate(90deg);
    @include mq(med) {
      top: sprem(495);
      bottom: auto;
      right: sprem(-16);
    }
  }
  .section-deco03 {
    top: rem(230);
    left: rem(-104);
    transform: rotate(90deg);
    @include mq(sp) {
      display: none;
    }
  }
}
.home_case--ttl {
  font-size: rem(20);
}
/* --- value_list --- */
.value_list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: rem(8);
  margin-left: rem(44);
  margin-right: rem(44);
  @include mq(sp) {
    grid-template-columns: repeat(1, 1fr);
    margin-left: 6%;
    margin-right: 6%;
  }
  .content {
    display: grid;
    justify-content: center;
    padding-top: rem(24);
    text-align: center;
    background: linear-gradient(180deg, rgba(2, 22, 122, 0.08) 30%, rgba(203, 215, 229, 0) 100%);
    box-shadow: 0 0 rem(20) 0 #fff;
    @include mq(sp) {
      padding: sprem(12);
      padding-right: 0;
      grid-template-columns: 1fr sprem(110);
      align-items: center;
      text-align: left;
    }
  }
  .txt {
    font-size: rem(20);
    line-height: 1.7;
    letter-spacing: perc(-0.4, 20, em);
    @include mq(med) {
      font-size: rem(13);
    }
    @include mq(sp) {
      font-size: sprem(14);
    }
    .bold {
      font-size: 135%;
      @include mq(sp) {
        font-size: 125%;
      }
    }
  }
  .img {
    display: block;
    margin: auto;
    max-width: 100%;
    width: rem(300);
    aspect-ratio: 1 / 1;
    height: fit-content;
    .lottie {
      max-width: 100%;
      width: rem(300);
      aspect-ratio: 1 / 1;
      height: fit-content;
    }
  }
  .content:nth-child(4) .txt .bold {
    letter-spacing: perc(-1.62, 27, em);
  }
}

/* home_product
********************************************** */
.home_product {
  .ttl-01 .animation:nth-child(2) {
    transition: background-position 3s ease-out;
  }
  .ttl-01 {
    @include mq(sp) {
      margin-bottom: rem(24);
    }
  }
}

/* --- tab --- */
@include mq(sp) {
  .tab_wrap {
    margin-bottom: sprem(32);
  }
}
.tab_area {
  display: grid;
  margin-bottom: rem(8);
  gap: rem(4);
  @include mq(sp, min, ps) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include mq(sp) {
    display: flex;
    flex-wrap: wrap;
  }
}
.tab_label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: rem(18) rem(28);
  color: var(--clr-mono-05);
  border: 1px solid var(--clr-mono-02);
  font-size: rem(24);
  letter-spacing: perc(-0.48, 24, em);
  line-height: 1.5;
  cursor: pointer;
  transition: var(--transit-default);
  @include mq(sp) {
    padding: sprem(12);
    font-size: sprem(14);
    justify-content: center;
    text-align: center;
  }
  .arrow {
    width: rem(44);
    background-color: var(--clr-wht);
    svg {
      fill: var(--clr-mono-04);
    }
    @include mq(sp) {
      display: none;
    }
  }
  &:hover,
  &.active {
    color: var(--clr-wht);
    background: var(--clr-primary-default);
    .arrow {
      background-color: var(--clr-sub-01);
      svg {
        fill: var(--clr-wht);
      }
    }
  }
}

.tab_panel {
  display: grid;
  grid-template-columns: 1fr rem(602);
  gap: 0;
  height: 0;
  overflow: hidden;
  background-color: #fff;
  @include mq(med) {
    //grid-template-columns: rem(300) 1fr;
  }
  @include mq(sp) {
    grid-template-columns: 1fr;
  }
  &.active {
    height: 100%;
  }
  .txtarea {
    background-color: var(--clr-wht);
    padding: rem(56) rem(72);
    @include mq(med) {
      padding: rem(40) rem(24);
    }
    @include mq(sp) {
      position: relative;
      padding: 0;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: sprem(36);
        background-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.7) 40%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }
  .side--list {
    @include mq(sp) {
      padding: sprem(28) sprem(32);
      overflow: auto;
    }
  }
  .side--list a {
    display: inline-block;
    color: var(--clr-sub-01);
    font-size: rem(20);
    @include mq(sp) {
      font-size: sprem(15);
    }
  }
  .imgarea {
    position: relative;
    background-color: var(--clr-sub-01);
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      display: block;
      background-size: rem(890) auto;
      background-image: url(../images/common/bg-deco-grid.png);
      background-position: center;
      opacity: 0.1;
    }
    @include mq(sp, min, ps) {
      height: 100%;
    }
    @include mq(sp) {
      height: sprem(320) !important;
      &::before {
        background-size: sprem(445) auto;
      }
      img {
        transform: scale(0.5, 0.5);
        transform-origin: center;
      }
    }
  }
  .tab--slider {
    position: relative;
    z-index: 10;
  }
  .tab--slider,
  .tab--slider .slick-list,
  .tab--slider .slick-list *:not(img):not(.txt):not(.num) {
    height: 100%;
  }
  .tab--slider li {
    display: flex !important;
    justify-content: center;
    align-items: center;
    counter-increment: number;
  }
  .tab--slider .slick-dots {
    position: absolute;
    top: rem(104);
    right: rem(64);
    display: flex;
    flex-direction: column;
    gap: rem(16);
    @include mq(sp) {
      top: sprem(66);
      right: sprem(35);
      transform: scale(0.75, 0.75);
      transform-origin: top right;
    }
  }
  .tab--slider .slick-dots button {
    @include rect(9);
    border-radius: 50%;
    font-size: rem(0);
    border: none;
    padding: 0;
    background-color: var(--clr-primary-default);
    transition: var(--transit-default);
    cursor: pointer;
  }
  .tab--slider .slick-dots .slick-active button {
    background-color: var(--clr-wht);
  }
  .txt {
    position: absolute;
    right: rem(32);
    bottom: 19%;
    padding: rem(4) rem(24);
    font-size: rem(24);
    color: var(--clr-wht);
    background-color: var(--clr-primary-dark);
    line-height: 1.7;
    @include mq(sp) {
      right: sprem(16);
      bottom: 15%;
      font-size: sprem(12);
    }
  }
  .num {
    position: absolute;
    top: rem(40);
    right: rem(44);
    display: flex;
    justify-content: center;
    align-items: center;
    @include rect(48);
    background-color: transparent;
    border: 1px solid var(--clr-primary-default);
    border-radius: 50%;
    @include mq(sp) {
      top: sprem(20);
      right: sprem(22);
      transform: scale(0.75, 0.75);
      transform-origin: top right;
    }
    &::before {
      content: counter(number, decimal-leading-zero);
      font-size: rem(20);
      letter-spacing: perc(-0.8, 20, em);
      color: var(--clr-wht);
      line-height: 1;
      font-family: var(--font-en);
      font-weight: 400;
    }
  }
  .circle {
    &::before {
      border-color: var(--clr-wht);
    }
  }
  .slick-active .circle {
    &:nth-child(1)::before {
      animation: circle_progress 2s linear forwards;
    }
    &:nth-child(2)::before {
      animation: circle_progress 2s linear 2s forwards;
    }
  }
  .scroll-img {
    position: absolute;
    bottom: 50%;
    z-index: 0;
    margin: auto;
    opacity: 0.7;
    mix-blend-mode: multiply;
    transform: translateY(70%);
  }
  .btn.pc-none {
    padding: 0 20px 20px 20px;
  }
}

/* home_blog
********************************************** */
.home_blog {
  background-color: var(--clr-wht);
  .ttl-01 {
    @include mq(sp) {
      margin-bottom: sprem(24);
    }
  }
  .ttl-01 .animation:nth-child(2) {
    transition: background-position 8s ease-out;
  }
  .section-deco {
    top: rem(4);
    right: rem(-54);
    transform: rotate(-270deg);
    @include mq(sp) {
      right: rem(-14);
    }
  }
  .posts-blog {
    @include mq(sp, min, ps) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: rem(120);
      grid-row-gap: rem(24);

      .post:nth-child(1) {
        grid-area: 1 / 1 / 4 / 2;
      }
      .post:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;
      }
      .post:nth-child(3) {
        grid-area: 2 / 2 / 3 / 3;
      }
      .post:nth-child(4) {
        grid-area: 3 / 2 / 4 / 3;
      }

      .post:nth-child(1) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 2fr 1fr;
        align-items: flex-start;
        .post--ttl {
          font-size: calc-fz(24);
        }
        .post--date,
        .cat_list a {
          font-size: calc-fz(18);
        }
      }
    }
    @include mq(sp) {
      margin-bottom: sprem(24);
    }
  }
}

/* home_news
********************************************** */
.home_news {
  background-color: var(--clr-wht);
  .ttl-01 .animation:nth-child(2) {
    transition: background-position 3s ease-out;
  }
  .ttlarea {
    width: rem(540);
    max-width: 100%;
    @include mq(med) {
      width: rem(340);
    }
  }
  .posts-news {
    flex: 1;
    @include mq(sp) {
      margin-bottom: sprem(24);
    }
  }
}

/* home_bnr
********************************************** */
.home_bnr {
  background-color: var(--clr-wht);
  .inner {
    max-width: rem(1370);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(30);
    @include mq(sp) {
      grid-template-columns: repeat(1, 1fr);
      gap: sprem(16);
    }
  }
}

.home_movie,
.home_teikibin {
  .link {
    position: relative;
    display: grid;
    grid-row: span 2;
    grid-template-rows: subgrid;
    height: rem(470);
    padding: rem(42) rem(48);
    background-color: var(--clr-primary-dark);
    @include mq(sp) {
      height: fit-content;
      padding: sprem(24) sprem(24);
    }
  }
  .ttl-03 {
    font-weight: 500;
  }
  .arrow {
    margin-top: auto;
    @include mq(sp) {
      transform-origin: left bottom;
      margin-left: auto;
      margin-right: -10%;
      margin-top: -5%;
    }
  }
}
@include mq(sp, min, ps) {
  .movie_slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: rem(270);
    opacity: 0.7;
    background-image: url(../images/home/movie-img.jpg);
    animation: loop_vertical_1st 80s linear infinite;
  }
  @keyframes loop_vertical_1st {
    0% {
      background-position-y: 0;
    }
    to {
      background-position-y: rem(-804);
    }
  }
}
.home_teikibin .link {
  @include mq(sp, min, ps) {
    background-image: url(../images/home/teikibin-img.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: rem(385) auto;
  }
  @include mq(med) {
    background-size: calc(sprem(385) / 2.5) auto;
  }
}

/* home_other
********************************************** */
.home_other {
  .inner {
    gap: 0;
    @include mq(sp) {
      gap: sprem(16);
    }
  }
}

.home_message,
.home_company,
.home_policy {
  width: 100%;
  transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  @media (hover: hover) {
    &:hover img {
      transform: scale(1.1, 1.1);
      overflow: hidden;
    }
  }
  .link {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: rem(640);
    padding: rem(36) rem(48);
    overflow: hidden;
    @include mq(sp) {
      height: sprem(140);
      padding: sprem(24);
      justify-content: center;
    }
  }
  .ttl-03 {
    font-weight: 500;
    @include mq(sp) {
      margin-bottom: 0;
    }
  }
  .txt {
    max-width: rem(280);
    @include mq(sp) {
      font-size: sprem(13);
    }
  }
  .img {
    position: absolute;
    inset: rem(-1012);
    z-index: 0;
    margin: auto;
    width: 100% !important;
    max-width: none;
    transition: var(--transit-default);
    @include mq(sp, min, ps) {
      height: 100%;
    }
  }
}

/* home_recruit
********************************************** */
.home_recruit {
  margin-top: rem(80);
  @include mq(sp) {
    margin-top: sprem(40);
  }
  .inner {
    @include mq(sp, min, ps) {
      position: absolute;
      inset: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: rem(1392);
    }
    @include mq(sp) {
      width: 100%;
    }
  }
  .content {
    width: rem(645);
    max-width: 100%;
    padding: rem(56) rem(72) rem(56);
    background-color: var(--clr-wht);
    @include mq(sp) {
      padding: sprem(28) sprem(36) sprem(40);
    }
  }
  .ttl-01 span:not(.subtitle) {
    font-size: rem(88);
    letter-spacing: perc(-7.04, 88, em);
    @include mq(sp) {
      font-size: sprem(56);
    }
  }
  .btn a {
    border: 1px solid rgba(0, 108, 224, 0.5);
  }
}
/* --- recruit_slider --- */
.recruit_slider {
  width: 100%;
  display: flex;
  overflow: clip;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    &:first-child {
      animation: loop_1st 80s -40s linear infinite;
    }
    &:last-child {
      animation: loop_2nd 80s linear infinite;
    }
  }
}
@keyframes loop_1st {
  0% {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes loop_2nd {
  0% {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(0);
  }
}
