@use "../abstracts" as *;

/* ##############################################################################

    FOOTER

############################################################################## */

.footer {
  position: relative;
  z-index: 10;
  background-color: var(--clr-sub-02);
}

/* footer_cta
********************************************** */
.footer_cta {
  padding-top: rem(120);
  @include mq(sp) {
    padding-top: sprem(60);
  }
}
.cta--list_wrap {
  position: relative;
  background-color: var(--clr-primary-default);
  transition: var(--transit-default);
  @media (hover: hover) {
    &:hover {
      background-color: transparent;
    }
  }

  .bg {
    position: absolute;
    inset: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: var(--transit-default);
  }
  .bg-contact {
    background-image: url(../images/common/contact-img01.jpg);
  }
  .bg-catalog-download {
    background-image: url(../images/common/contact-img02.jpg);
  }
  .bg-faq {
    background-image: url(../images/common/contact-img03.jpg);
  }
  &.contact .bg-contact,
  &.catalog-download .bg-catalog-download,
  &.faq .bg-faq {
    opacity: 1;
  }
}
.cta--list {
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @include mq(sp) {
    grid-template-columns: repeat(1, 1fr);
  }
  .content {
    position: relative;
    &:not(:last-child)::before {
      content: "";
      position: absolute;
      opacity: 0.7;
      background: var(--clr-primary-dark);
      transition: var(--transit-default);
      @include mq(sp, min, ps) {
        top: rem(10);
        right: 0;
        bottom: rem(10);
        width: 1px;
      }
      @include mq(sp) {
        left: 0;
        bottom: 0;
        right: 0;
        height: 1px;
      }
    }
  }
  @media (hover: hover) {
    &:hover .content::before {
      background: var(--clr-wht);
    }
  }
  .link {
    display: flex;
    align-items: center;
    height: rem(420);
    color: var(--clr-wht);
    @include mq(sp, min, ps) {
      justify-content: center;
      flex-direction: column;
      padding-top: rem(10);
    }
    @include mq(sp) {
      height: sprem(160);
      justify-content: space-between;
    }
  }
  .jp {
    margin-bottom: rem(8);
    font-weight: 500;
    letter-spacing: perc(-1.92, 32, em);
  }
  .en {
    letter-spacing: perc(-0.96, 16, em);
    opacity: 0.5;
    @include mq(sp, min, ps) {
      margin-bottom: rem(78);
    }
    @include mq(sp) {
      text-align: left;
      padding-left: 0.125em;
    }
  }
}

.arrow2 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include rect(88);
  border: solid 1px;
  background-color: transparent;
  @include mq(sp) {
    transform: scale(0.6, 0.6);
    transform-origin: center right;
  }
  &::after {
    content: "";
    display: inline-block;
    @include rect(8);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
  }
}
@media (hover: hover) {
  .link:hover .arrow2 {
    background-color: var(--clr-wht);
    &::after {
      border-color: var(--clr-primary-dark);
    }
  }
}
/* footer_top
********************************************** */
.footer_top {
  padding-top: rem(114);
  @include mq(sp) {
    padding-top: sprem(50);
  }
}

/* ---fnav --- */
.fnav--menu {
  display: flex;
  gap: rem(74);
  @include mq(sp) {
    gap: sprem(24);
    margin-bottom: sprem(40);
  }
}
.fnav--menu a {
  display: block;
  @media (hover: hover) {
    &[href="javascript:void(0);"]:hover {
      color: var(--clr-wht);
    }
    &:not([href="javascript:void(0);"]):hover {
      opacity: 0.7;
    }
  }
}
.fnav--menu .menu-item-has-children > a {
  margin-bottom: rem(16);
  font-family: var(--font-en);
  font-weight: 600;
  font-size: rem(18);
  opacity: 0.7;
}
.fnav--menu .sub-menu {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: rem(12);
  @include mq(sp) {
    gap: sprem(16);
  }
}
.fnav--menu .sub-menu a {
  line-height: 1;
  color: var(--clr-text-light);
  @include mq(sp) {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0.5em;
      margin: auto;
      @include sprect(8);
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
    }
  }
}
.fnav--menu > li:nth-child(1) .sub-menu li:nth-last-child(2) {
  @include mq(sp, min, ps) {
    margin-top: rem(28);
  }
}

/* ---scroll_top --- */
.scroll_top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: rem(14);
  color: var(--clr-wht);
  gap: rem(8);
  opacity: 0.7;
  .arrow {
    @include rect(22);
    background-color: transparent;
    background-image: url(../images/common/icon-arrow-wh.svg);
    background-repeat: no-repeat;
    background-size: 60% auto;
    background-position: center;
    border: solid 1px;
    transform: translateY(-25%) rotate(-90deg);
  }
  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
}

/* footer_btm
*************************************************** */
.footer_btm {
  padding: rem(88) 0 rem(56);
  @include mq(sp) {
    padding: sprem(44) 0 sprem(28);
  }
}
.footer_btm:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

/* ---footer--logo --- */
.footer--logo {
  @include mq(sp) {
    margin-bottom: rem(12);
  }
}
.footer--logo img {
  width: rem(840) !important;
  filter: brightness(0) invert(1);
  @include mq(sp) {
    width: rem(240) !important;
  }
}

/* ---sns_area --- */
.sns_area .flex-j-end,
.footer_btm > .inner > div > .flex-j-end {
  @include mq(sp) {
    justify-content: flex-start;
  }
}
.sns_area a {
  display: grid;
  place-items: center;
  @include rect(40);
  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
}
.sns_area svg {
  @include rect(24);
  fill: var(--clr-text-light);
  &.icon-x {
    @include rect(22);
  }
}

.privacy {
  @include mq(sp) {
    text-align: left;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
}
.privacy a:hover {
  opacity: 0.7;
}
.copyright {
  gap: 1em;
  justify-content: flex-end;
  @include mq(sp) {
    gap: 0.25em;
    margin-top: sprem(24);
  }
  small {
    font-size: rem(14);
    color: var(--clr-text-secondary);
  }
}
.pbl a,
.pbl img {
  display: block;
}
.pbl a {
  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
    }
  }
}
