@use "../abstracts" as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: calc-clamp($fz-pc, 1080);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-padding-top: rem($header-hgt-fx);
  @include mq(sp, min, ps) {
    @include mq(med) {
      font-size: rem($fz-sp);
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: min(spvw($fz-sp), sprem($fz-sp));
    scroll-padding-top: sprem($header-hgt-sp);
  }
  &.fixed {
    overflow-y: hidden;
  }
}
body {
  font-family: var(--font-primary);
  line-height: var(--line-height-default);
  font-weight: 500;
  font-feature-settings: "palt";
  color: var(--clr-text-primary);
  height: 100%;
  word-break: break-word;
  @include mq(sp, min, ps) {
    // フォントサイズ変更する場合　例：18pxの例：calc-clamp(18, $wid-var)
    font-size: calc-clamp($fz-pc, $wid-var);
    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: 1080px;
      }
    }
  }
  @include mq(sp) {
    -webkit-text-size-adjust: none;
  }
}
* {
  letter-spacing: var(--ltr-space-default);
}
body .base-bg {
  position: relative;
  padding-top: rem($header-hgt);
  overflow: clip;
  @include mq(sp) {
    padding-top: sprem($header-hgt-sp);
  }
  .base-bg_img {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/common/bg-noise.jpg);
    opacity: 0.85;
    pointer-events: none;
  }
  &::before,
  &::after {
    content: "";
    position: fixed;
    right: 0;
    left: 0;
    margin: auto;
    display: block;
    max-width: 100%;
    background-size: cover;
    background-position: center;
    pointer-events: none;
    @include mq(sp, min, ps) {
      height: fit-content;
    }
    @include mq(sp) {
      top: 0;
      bottom: sprem(-16);
      height: 100%;
    }
  }
  &::before {
    z-index: 1;
    width: rem(1432);
    background-image: url(../images/common/bg-deco-round-wh.png);
    @include mq(sp, min, ps) {
      top: 0;
      aspect-ratio: 1432 / 1148;
    }
  }
  &::after {
    z-index: 0;
    width: rem(1560);
    background-image: url(../images/common/bg-deco-round.png);
    mix-blend-mode: multiply;
    opacity: 0.2;
    @include mq(sp, min, ps) {
      top: rem(-190);
      aspect-ratio: 1560 / 1394;
    }
  }
}
body.home .base-bg {
  padding-top: calc(rem($header-hgt) + rem(8));
  @include mq(sp) {
    padding-top: calc(sprem($header-hgt-sp) + sprem(8));
  }
}
ul,
ol {
  list-style: none;
}
small {
  font-size: calc-fz(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-text-primary);
  text-decoration: none;
  transition: var(--transit-default);
  outline: none;
}
a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:focus {
  text-decoration: none !important;
}
a[href^="tel:"] {
  @include mq(sp, min, ps) {
    pointer-events: none;
    text-decoration: none !important;
  }
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--line-height-hdr);
}
svg {
  transition: var(--transit-default);
  &[preserveAspectRatio="none"] {
    height: auto;
  }
}
.scroll-img {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: rem(230);
  bottom: 0;
  z-index: -1;
  @include mq(sp) {
    height: calc(sprem(230) / 2.5);
  }
  .scroll-img--cont {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    animation: scroll-img 100s linear infinite;
    pointer-events: none;
    img {
      margin-right: rem(18);
      max-width: inherit;
      @include mq(sp) {
        margin-right: sprem(10);
        width: calc(sprem(3314) / 2.5) !important;
      }
    }
  }
}
.block {
  display: block;
}
