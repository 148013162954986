@use "../abstracts" as *;

/* anchor_link
********************************************** */
.anchor_link--list_wrap {
  @include mq(sp) {
    overflow: auto;
  }
}
.anchor_link--list {
  @include mq(sp) {
    flex-wrap: inherit;
    white-space: nowrap;
    width: 100%;
    overflow: auto;
    @include mq(sp, min, ps) {
      margin-inline: calc(50% - 50vw);
      padding-inline: 7%;
    }
  }
}
.anchor_link--list li {
  border-bottom: solid 1px var(--clr-mono-03);
}
.anchor_link--list a {
  font-size: rem(18);
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: rem(14) rem(22) rem(14) rem(8);
  line-height: var(--line-height-hdr);
  color: var(--clr-mono-04);
  @include mq(sp) {
    color: var(--clr-primary-dark);
    font-size: sprem(15);
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: rem(10);
    width: rem(6);
    height: rem(6);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: translateY(-50%) rotate(45deg);
    transition: var(--transit-default);
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    width: 0;
    height: 1px;
    background-color: var(--clr-primary-default);
    transition: var(--transit-default);
  }
}
.anchor_link--list .active a,
.anchor_link--list a:hover {
  color: var(--clr-primary-dark);
  &::after {
    width: 100%;
  }
}

@include mq(sp) {
  .anchor_link--list-ttl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: sprem(10) sprem(16) sprem(10) sprem(38);
    font-size: sprem(14);
    border-block: 1px solid #d2d2d2;
    background-color: #fdfdfd;
    .toggle {
      position: relative;
      width: sprem(29);
      border-radius: 50%;
      aspect-ratio: 1;
      background-color: var(--clr-sub-01);
      &::before,
      &::after {
        content: "";
        position: absolute;
        inset: 0;
        margin: auto;
        width: sprem(12);
        height: 1px;
        color: var(--clr-wht);
        border-bottom: 1px solid;
        transition: var(--transit-default);
      }
      &::after {
        transform: rotate(90deg);
      }
    }
    &.is-open .toggle {
      transform: rotate(180deg);
      &::after {
        opacity: 0;
      }
    }
  }
  .anchor_link--list {
    display: none;
    padding: sprem(10) sprem(20) sprem(20);
    background-color: var(--clr-bg-01);
  }
}
