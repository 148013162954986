@use "../abstracts" as *;

/* cta_box
**************************************** */
.cta_box {
  position: relative;
  padding: rem(44) 0 rem(52);
  background: var(--clr-sub-01);
  color: var(--clr-wht);
  text-align: center;
  @include mq(sp) {
    padding: sprem(22) 0 sprem(26);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: rem(239);
    aspect-ratio: 239 / 328;
    height: fit-content;
    background-image: url(../images/common/cta_box-bg.svg);
    background-repeat: no-repeat;
    background-size: contain;
    @include mq(sp) {
      width: calc(sprem(239) / 2);
    }
  }
  &::before {
    top: 0;
    right: 0;
  }
  &::after {
    left: 0;
    bottom: 0;
    transform: rotate(180deg);
  }
  .ttl {
    margin-bottom: rem(24) !important;
    font-size: rem(32);
    line-height: 1.6;
    letter-spacing: perc(-0.96, 32, em);
    font-weight: 600;
    @include mq(sp) {
      margin-bottom: sprem(16) !important;
      font-size: sprem(22);
    }
  }
  .txt {
    margin-bottom: rem(32) !important;
    line-height: 1.9;
    letter-spacing: perc(0.48, 16, em);
    @include mq(sp) {
      margin-bottom: sprem(24) !important;
      font-size: sprem(15);
    }
  }
  .flex {
    margin: 0;
    @include mq(sp) {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

/* cta_tel
**************************************** */
.cta_tel a {
  display: block;
  margin-inline: auto;
  padding: rem(12);
  line-height: var(--line-height-hdr);
  border-radius: rem(4);
  background-color: var(--clr-wht);
  svg {
    width: rem(20);
    height: rem(26);
    fill: var(--clr-primary-dark);
  }
}
.cta_tel .tel {
  font-size: calc-fz(20);
}
.cta_tel .num {
  font-size: rem(34);
  letter-spacing: -0.04em;
}
.cta_tel .hours {
  font-size: calc-fz(13);
}

/* cta_contact
**************************************** */
.cta_contact a {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(10);
  border-radius: 0;
  background-color: var(--clr-primary-dark);
  border: solid 1px var(--clr-primary-dark);
  color: var(--clr-wht);
  letter-spacing: 0;
  line-height: 1.9;
  padding: rem(16) rem(38);
  overflow: hidden;
  @include mq(1600) {
    padding: rem(16);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: left top;
    animation: hoverout_contact 0.3s cubic-bezier(0.45, 0, 0.55, 1) forwards;
    z-index: -1;
    background: linear-gradient(90deg, var(--clr-primary-dark) 29%, var(--clr-primary-dark) 58.5%, #30a9ce 79.5%, #adf515 87.5%, var(--clr-primary-dark) 89.5%);
  }
  @media (hover: hover) {
    &:hover {
      border-color: var(--clr-primary-dark);
      background-color: var(--clr-primary-dark);
    }
    &:hover::after {
      animation: hover_contact 0.3s cubic-bezier(0.45, 0, 0.55, 1) forwards;
    }
  }
  @keyframes hoverout_contact {
    0% {
      opacity: 1;
      transform: scale(1.7, 1);
    }
    100% {
      opacity: 0;
      transform: scale(1.7, 1);
    }
  }
  @keyframes hover_contact {
    0% {
      transform: scale(0, 1);
    }
    100% {
      transform: scale(1.7, 1);
    }
  }
  .icn {
    display: inline-block;
    padding: 0.5em 0.75em;
    line-height: 1;
    font-size: rem(13);
    font-family: var(--font-primary-r);
    color: var(--clr-wht);
    background-color: var(--clr-primary-default);
    transform: translateY(-5%);
  }
}
.cta_contact .micro_copy {
  position: absolute;
  top: calc(-1em - rem(10));
  inset-inline: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: rem(13);
  color: var(--clr-secondary-default);
  text-align: center;
  line-height: 1;
  &::before,
  &::after {
    content: "";
    width: rem(2);
    height: rem(16);
    background-color: var(--clr-secondary-default);
  }
  &::before {
    transform: rotate(-45deg);
  }
  &::after {
    transform: rotate(45deg);
  }
}

.cta_download a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(10);
  border-radius: 0;
  background-color: var(--clr-wht);
  border: solid 1px var(--clr-sub-03);
  color: var(--clr-primary-dark);
  letter-spacing: 0;
  line-height: 1.9;
  padding: rem(16) rem(38);
  @include mq(1600) {
    padding: rem(16);
  }
  svg {
    transform: translateY(-10%);
  }
  path {
    transition: var(--transit-default);
  }
  @media (hover: hover) {
    &:hover {
      color: var(--clr-wht);
      path {
        fill: var(--clr-wht);
      }
    }
  }
}
