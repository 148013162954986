@use "../abstracts" as *;

/* icon
**************************************** */
.icon-mail {
  width: rem(21);
  aspect-ratio: 21/16;
}

/* deco
**************************************** */
.section-deco {
  position: absolute;
  width: rem(54);
  max-width: 100%;
  height: fit-content;
  aspect-ratio: 54 / 21;
  background-image: url(../images/common/section-deco.svg);
  background-size: cover;
  @include mq(sp) {
    width: sprem(27);
  }
}
.section-deco-line {
  position: absolute;
  width: rem(160);
  max-width: 100%;
  height: fit-content;
  aspect-ratio: 160 / 4;
  background-image: url(../images/common/section-deco-line.svg);
  background-size: cover;
  @include mq(sp) {
    width: sprem(80);
  }
}
.section-deco-grid {
  position: absolute;
  z-index: -1;
  width: rem(890);
  max-width: 100%;
  height: fit-content;
  aspect-ratio: 890 / 890;
  background-image: url(../images/common/bg-deco-grid.png);
  background-repeat: no-repeat;
  background-size: cover;
  @include mq(sp) {
    width: sprem(445);
  }
}