@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem($header-hgt);
  z-index: z(header);
  transition: var(--transit-default);
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(rem(17));
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: rem(40);
    padding-right: rem(32);
  }
  @include mq(med) {
    height: sprem($header-hgt-sp);
    & > .inner {
      padding-left: sprem(20);
      padding-right: sprem(80);
    }
  }
}
.home .header {
  top: rem(8);
  @include mq(med) {
    top: sprem(4);
  }
  &::before {
    content: "";
    position: absolute;
    top: rem(-8);
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: rem(8);
    background-image: url(../images/common/gradient.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    transition: var(--transit-default);
    @include mq(med) {
      top: sprem(-4);
      height: sprem(4);
    }
  }
}
.subpage .header:not(.header-sm) {
  border-bottom: 1px solid var(--clr-primary-dark);
}
.template-contact-simple .header {
  position: absolute;
}
.header-sm {
  background: var(--clr-wht);
  box-shadow: 0 0 rem(14) 0 rgba(0, 0, 0, 0.05);
  @include mq(med, min, ps) {
    top: rem(8);
    left: rem(64);
    right: rem(64);
    height: rem($header-hgt-fx) !important;
  }
  @include mq(med) {
    top: 0 !important;
  }
}
.ipad .header-sm {
  top: 0 !important;
  inset-inline: 0 !important;
}
.home .header-sm::before {
  height: 0;
}

/* --- logo -- */
.header--logo a,
.footer--logo a {
  display: inline-block;
  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
}
.header--logo img {
  width: rem(246) !important;
  @include mq(1600) {
    width: rem(180) !important;
  }
  @include mq(med) {
    width: sprem(140) !important;
  }
}
@include mq(1600, min, ps) {
  .header-sm .header--logo img {
    width: rem(200) !important;
  }
}

/* gnav
********************************************** */
.gnav,
.gnav--menu,
.gnav--menu li,
.gnav--link {
  @include mq(med, min, ps) {
    height: 100%;
  }
}
.gnav {
  @include mq(med, min, ps) {
    display: flex;
    align-items: center;
  }
  @include mq(med) {
    position: fixed;
    top: sprem($header-hgt-sp);
    right: 0;
    z-index: 300;
    width: 100%;
    height: calc(100vh - sprem($header-hgt-sp));
    margin: 0;
    padding: rem(40) 12%;
    opacity: 0;
    pointer-events: none;
    overflow: auto;
    background-color: var(--clr-wht);
    transform: translateX(-100%);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(0);
    }
  }
}
.home .header .gnav,
.subpage .header-sm .gnav {
  @include mq(med) {
    border-top: 1px solid var(--clr-primary-dark);
  }
}

/* --- menu --- */
.gnav--menu,
.gnav--cta {
  @include mq(med) {
    display: block;
  }
}
.gnav--menu li {
  @include mq(med) {
    position: relative;
    display: block;
    width: 100%;
  }
}
.gnav--link {
  padding: 0 rem(24);
  @include mq(1600) {
    padding: 0 rem(18);
  }
  @include mq(med) {
    display: block;
    padding: sprem(8) 0;
    font-size: sprem(18);
  }
  @include mq(med, min, ps) {
    text-align: center;
    @include center-flex;
    font-size: rem(18);
  }
}
.gnav--menu li:not(.menu-item-has-children) a::after {
  @include mq(med) {
    content: "";
    float: inline-end;
    inline-size: sprem(8);
    margin-block: calc((1lh - sprem(8)) / 2);
    margin-right: sprem(2);
    aspect-ratio: 1;
    border-top: 2px solid var(--clr-primary-dark);
    border-right: 2px solid var(--clr-primary-dark);
    transform: rotate(45deg);
  }
}
.gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
.gnav--menu > li.current-menu-item > .gnav--link {
  @include mq(med, min, ps) {
    color: var(--clr-primary-default);
  }
}

@include mq(sp) {
  .gnav--tel {
    .ttl {
      text-align: center;
      color: var(--clr-wht);
      background-color: var(--clr-sub-01);
      line-height: 1.6;
      letter-spacing: -0.05em;
      padding: 0.25rem;
    }
    &.cta_tel .num {
      font-size: sprem(40);
    }
    &.cta_tel a svg {
      width: sprem(22) !important;
      height: auto;
      aspect-ratio: 20/26;
      fill: currentColor;
    }
    &.cta_tel .hours {
      font-size: sprem(12);
    }
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  > .gnav--link > span {
    display: flex;
    align-items: center;
    @include mq(med, min, ps) {
      justify-content: center;
    }
    @include mq(med) {
      justify-content: space-between;
    }
  }
  > .gnav--link > span::after {
    @include mq(med, min, ps) {
      content: "";
      display: inline-block;
      @include rect(8);
      margin-left: rem(12);
      border-top: 2px solid;
      border-right: 2px solid;
      transform: translateY(-40%) rotate(135deg);
      transition: var(--transit-default);
    }
  }
}
.head_sub_nav--wrap {
  z-index: 100;
  @include mq(med, min, ps) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(48) rem(24) rem(48) rem(56);
    background: var(--clr-wht);
    box-shadow: 0 rem(14) rem(14) 0 rgba(0, 0, 0, 0.05);
    transition: var(--transit-default);
    opacity: 0;
    pointer-events: none;
  }
  @include mq(med) {
    display: none;
  }
  > .gnav_sub_toggle {
    display: none;
  }
}
.header:not(.header-sm) .head_sub_nav--wrap {
  transform: translateY(1px);
}
.gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
  opacity: 1;
  pointer-events: auto;
}
.menu-item-has-children > .active + .head_sub_nav--wrap {
  @include mq(med) {
    opacity: 1;
    pointer-events: auto;
  }
}
.head_sub_nav--ttl-wrap {
  flex-shrink: 0;
}
.head_sub_nav--ttl {
  flex-shrink: 0;
  width: rem(442);
  color: var(--clr-primary-default);
  font-size: rem(32);
  font-weight: 700;
  letter-spacing: perc(-1.92, 32, em);
  .en {
    display: block;
    font-size: rem(16);
    letter-spacing: perc(-0.96, 16, em);
    opacity: 0.5;
    font-weight: 500;
  }
  @include mq(med) {
    &,
    & + .btn {
      display: none;
    }
  }
}
.head_sub_nav {
  @include mq(med, min, ps) {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: rem(50);
    flex-grow: 1;
  }
  @include mq(med) {
    position: relative;
    margin-bottom: sprem(16);
  }
}
.head_sub_nav a {
  display: block;
  padding: 0;
  @include mq(med) {
    display: flex;
    align-items: center;
    gap: sprem(20);
    margin-top: sprem(8);
    &::after {
      position: absolute;
      right: 0;
    }
  }
  @media (hover: hover) {
    &:hover {
      color: var(--clr-primary-default);
      .img img {
        transform: scale(1.1, 1.1);
        transform-origin: center;
      }
    }
  }
  .img {
    display: block;
    overflow: hidden;
    img {
      transition: all 0.3s ease-out;
    }
    @include mq(med, min, ps) {
      margin-bottom: rem(4);
    }
    @include mq(med) {
      width: 25%;
    }
  }
  .txt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: perc(-0.36, 18, em);
    line-height: 1.5;
    text-align: left;
    @include mq(med) {
      font-size: sprem(15);
    }
  }
  .txt-box .title,
  .txt-box .subtitle {
    display: block;
  }
  .txt-box .subtitle {
    font-size: rem(15);
    @include mq(sp) {
      font-size: calc-fz(14);
    }
  }
  .txt-en {
    display: flex;
    align-items: center;
    gap: rem(8);
    font-size: rem(14);
    font-weight: 500;
    letter-spacing: perc(-0.96, 16, em);
    color: var(--clr-text-secondary);
    opacity: 0.5;
    text-transform: capitalize;
    @include mq(med) {
      display: none;
    }
  }
  .icon-arrow {
    width: 1em;
    height: 1em;
    fill: var(--clr-text-secondary);
    @include mq(med) {
      display: none;
    }
  }
}

/* --- gnav-tab --- */
.gnav-tab_wrap {
  display: grid;
  grid-template-columns: rem(240) 1fr;
  gap: rem(126);
  @include mq(med, min, ps) {
    flex-grow: 1;
  }
  @include mq(med) {
    grid-template-columns: 1fr;
    gap: sprem(16);
    padding-bottom: sprem(21);
  }
}
.gnav-tab_area {
  display: flex;
  gap: rem(8);
  flex-direction: column;
  @include mq(med) {
    display: grid;
    gap: sprem(2);
    grid-template-columns: 0.8fr 1fr 1.3fr 0.9fr;
  }
}
.gnav-tab_label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: rem(10);
  color: var(--clr-mono-05);
  border: solid 1px var(--clr-mono-02);
  font-size: rem(18);
  font-weight: 600;
  line-height: 1.6;
  cursor: pointer;
  transition: var(--transit-default);
  &:hover,
  &.active {
    color: var(--clr-primary-dark);
    border-color: var(--clr-primary-default);
  }
  @include mq(med, min, ps) {
    min-height: rem(58);
  }
  @include mq(med) {
    padding: sprem(12) 0;
    font-size: sprem(13);
    line-height: 1.4;
    justify-content: center;
    text-align: center;
  }
}
.gnav-tab_panel {
  height: 0;
  overflow: hidden;
  &.active {
    height: 100%;
  }
  .side--list {
    display: block;
    column-count: 2;
    max-width: rem(500);
  }
  .side--list li {
    margin-bottom: rem(8);
  }
  .side--list a::after {
    @include mq(med) {
      display: none;
    }
  }
}

/* --- gnav_sub_toggle  --- */
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: absolute;
    top: 0;
    right: sprem(-20);
    @include sprect(48);
    transition: var(--transit-default);
    color: var(--clr-primary-dark);
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      margin: auto;
      display: inline-block;
      @include rect(8);
      border-top: 2px solid;
      border-right: 2px solid;
      transform: translateY(-40%) rotate(135deg);
      transition: var(--transit-default);
    }
    &.active::before {
      transform: rotate(-45deg);
    }
  }
}

/* ---btn --- */
.gnav_btn {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: fixed;
    top: sprem(8);
    right: sprem(8);
    @include sprect(48);
    background-color: var(--clr-primary-dark);
    cursor: pointer;
    z-index: 9999;
    transition: var(--transit-default);
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: scale(0, 1);
      transform-origin: left top;
      transition: 0.2s cubic-bezier(0.45, 0, 0.55, 1);
      z-index: -1;
      background: linear-gradient(90deg, #004ea2 29%, #004ea2 58.5%, #30a9ce 79.5%, #adf515 87.5%, #0068b7 89.5%);
    }
    &.active {
      border-color: #0068b7;
    }
    &.active::after {
      transform: scale(1.3, 1);
    }
  }
}
.gnav_btn--lines {
  @include mq(med) {
    position: relative;
    @include sprect(24, 16);
  }
}
.gnav_btn--lines span {
  @include mq(med) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-wht);
    transition: var(--transit-default);
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}
.active .gnav_btn--lines span {
  @include mq(med) {
    &:nth-of-type(1) {
      transform: translateY(sprem(7)) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(sprem(-6)) rotate(45deg);
    }
  }
}
.gnav--cta {
  text-align: center;
  @include mq(med) {
    margin-top: sprem(32);
  }
}
.gnav--cta li:not(:last-child) {
  @include mq(med) {
    margin-bottom: sprem(16);
  }
}
.gnav--contact {
  @include mq(med, min, ps) {
    margin-left: rem(8);
  }
}
.gnav--contact a {
  @include mq(med, min, ps) {
    min-width: rem(202);
  }
  @include mq(med) {
    margin-left: auto;
    margin-right: auto;
  }
}
