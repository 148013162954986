@use "../abstracts" as *;

/* btn
********************************************** */
.arrow {
  display: grid;
  place-content: center;
  width: rem(44);
  aspect-ratio: 1;
  border-radius: 50%;
  color: var(--clr-wht);
  background-color: var(--clr-sub-01);
  transition: var(--transit-default);
  pointer-events: none;
  .icon-arrow {
    width: rem(16);
    height: rem(10);
    fill: var(--clr-wht);
    transition: var(--transit-default);
  }
}
.btn:not(:last-child) {
  margin-bottom: rem(8);
}
.btn a,
.btn button {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: rem(18) rem(80) rem(18) rem(38);
  font-size: rem(18);
  border-radius: 9999px;
  width: fit-content;
  min-width: min(rem(280), 100%);
  color: var(--clr-primary-dark);
  background-color: var(--clr-wht);
  text-decoration: none;
  border: solid 1px #e7e5e1;
  transition: var(--transit-default);
  cursor: pointer;
  font-family: var(--font-primary);
  @include mq(sp) {
    min-width: 100%;
  }
  .arrow {
    position: absolute;
    right: rem(14);
    top: calc(50% - rem(44) / 2);
  }
  @media (hover: hover) {
    &:hover {
      background-color: var(--clr-sub-01);
      color: var(--clr-wht);
      .arrow {
        color: var(--clr-text-primary);
        background-color: var(--clr-wht);
      }
      .arrow svg {
        fill: var(--clr-sub-01);
      }
    }
  }
  svg {
    right: rem(24);
    fill: currentColor;
  }
}
.btn.btn-ctr a {
  @include auto-margin;
}

/* --- btn-ble --- */
.btn-ble a,
.btn-ble button {
  background-color: var(--clr-sub-01);
  color: var(--clr-wht);
  .arrow {
    color: var(--clr-text-primary);
    background-color: var(--clr-wht);
  }
  .arrow svg {
    fill: var(--clr-sub-01);
  }
  @media (hover: hover) {
    &:hover {
      color: var(--clr-primary-dark);
      background-color: var(--clr-wht);
      .arrow {
        color: var(--clr-wht);
        background-color: var(--clr-sub-01);
      }
      .arrow svg {
        fill: var(--clr-wht);
      }
    }
  }
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--clr-secondary-default);
  @media (hover: hover) {
    &:hover {
      background-color: var(--clr-secondary-dark);
    }
  }
}

/* --- btn-arrow --- */
.btn-arrow a {
  display: inline-flex;
  align-items: center;
  gap: rem(10);
  margin-inline: -1em;
  padding: 1em;
  @media (hover: hover) {
    &:hover {
      color: var(--clr-primary-default);
    }
  }
  &::after {
    content: "";
    @include rect(8);
    color: var(--clr-primary-default);
    transform: rotate(45deg);
    border-top: 2px solid;
    border-right: 2px solid;
  }
}

/* --- 横並び --- */
.lps_sec .flex > .btn {
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  &:only-child {
    @include mq(sp, min, ps) {
      width: fit-content;
      min-width: min(rem(280), 100%);
    }
  }
  a {
    min-width: 100%;
    height: 100%;
  }
}
.flex > .btn {
  margin-bottom: 0;
}
